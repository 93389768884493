import {
  ColumnDef,
  ColumnFiltersState,
  ColumnSort,
  SortingState,
  VisibilityState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React from 'react';
import { DataTableFilterField } from '../types';

interface UseDataTableProps<TData, TValue> {
  data: TData[];
  setData?: React.Dispatch<React.SetStateAction<TData[]>>;
  columns: ColumnDef<TData, TValue>[];
  filterFields?: DataTableFilterField<TData>[];
  defaultSorting?: ColumnSort[];
  columnVisibilityState?: VisibilityState;
  pageSize?: number;
  resetPageIndexOnUpdate?: boolean;
}

function useSkipper() {
  const shouldSkipRef = React.useRef(true);
  const shouldSkip = shouldSkipRef.current;

  // Wrap a function with this to skip a pagination reset temporarily
  const skip = React.useCallback(() => {
    shouldSkipRef.current = false;
  }, []);

  React.useEffect(() => {
    shouldSkipRef.current = true;
  });

  return [shouldSkip, skip] as const;
}

export function useDataTable<TData, TValue>({
  data,
  setData,
  columns,
  filterFields = [],
  defaultSorting,
  columnVisibilityState,
  pageSize,
  resetPageIndexOnUpdate,
}: UseDataTableProps<TData, TValue>) {
  function setDefaultFilters(
    filters: DataTableFilterField<TData>[]
  ): ColumnFiltersState {
    const defaultFilters: ColumnFiltersState = [];

    filters.map((filter: DataTableFilterField<TData>) => {
      if (filter.defaultValues) {
        defaultFilters.push({
          id: filter.label,
          value: filter.value,
        });
      }
    });

    return defaultFilters;
  }

  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: pageSize || 10,
  });

  const [sorting, setSorting] = React.useState<SortingState>(
    defaultSorting || []
  );
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    (filterFields && setDefaultFilters(filterFields)) || []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>(columnVisibilityState ?? {});
  const [rowSelection, setRowSelection] = React.useState({});

  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    autoResetPageIndex:
      typeof resetPageIndexOnUpdate === 'boolean'
        ? resetPageIndexOnUpdate
        : autoResetPageIndex,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      pagination,
    },
    meta: {
      updateRow:
        setData &&
        ((rowIndex: number, updatedRow: TData) => {
          setData((old) =>
            old.map((row, index) => (index === rowIndex ? updatedRow : row))
          );
        }),
      deleteRow:
        setData &&
        ((rowIndex: number) => {
          setData((old) => old.filter((_, index) => index !== rowIndex));
        }),
    },
  });

  return { table };
}
