import { ErrorBoundary } from '@sentry/react';
import { InputModeEnum } from '@shared/api/types';
import { RepeatableRowComponentProps } from '@shared/components/form/types';
import { Button } from '@shared/components/ui/button';
import { ComponentType, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Icons } from '../content/icons';

interface RepeatableFormRowsProps {
  name: string;
  component: ComponentType<RepeatableRowComponentProps>;
  componentVariant?: 'dish' | 'product';
  newRowObject: object;
  includeErrorMessage?: boolean;
  showAddButton?: boolean;
  mode?: InputModeEnum;
}

export function RepeatableFormRows({
  name,
  component: Component,
  componentVariant,
  newRowObject,
  includeErrorMessage = false,
  showAddButton = true,
  mode = InputModeEnum.packs,
}: RepeatableFormRowsProps) {
  const [disableAdd, setDisableAdd] = useState(false);
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: name,
  });

  return (
    <div className="space-y-2">
      <ErrorBoundary
        fallback={<div>There was an error loading your {name}</div>}
      >
        {fields.map((field, index) => {
          return (
            <Component
              key={field.id}
              index={index}
              remove={remove}
              setDisableAdd={setDisableAdd}
              includeErrorMessage={includeErrorMessage}
              variant={componentVariant}
              mode={mode}
            />
          );
        })}
        {showAddButton && (
          <Button
            type="button"
            variant="outline"
            size="sm"
            disabled={disableAdd}
            onClick={() => append(newRowObject)}
            icon={<Icons.plus className="size-4" />}
          >
            Add
          </Button>
        )}
      </ErrorBoundary>
    </div>
  );
}
