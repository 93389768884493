import { cn } from '@shared/lib/utils';
import { ClassValue } from 'clsx';
import React, { ReactNode } from 'react';

type TextVariant =
  | 'body'
  | 'subtle'
  | 'cardTitle'
  | 'heroValue'
  | 'unit'
  | 'section'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'reportBody';

interface VariantStyle {
  className: ClassValue;
}

const variantStyles: Record<TextVariant, VariantStyle> = {
  body: { className: 'text-base' },
  subtle: { className: 'text-sm text-gray-600' },
  cardTitle: { className: 'text-lg font-medium' },
  section: { className: 'text-xl font-medium' },
  heroValue: { className: 'text-4xl font-medium' },
  unit: { className: 'text-xs text-gray-400' },
  reportBody: { className: 'text-md font-light' },
  h1: { className: 'text-4xl font-semibold text-primary-800' },
  h2: { className: 'text-3xl font-semibold' },
  h3: { className: 'text-2xl font-semibold font-heading text-primary-800' },
  h4: { className: 'text-xl font-semibold text-primary-800 font-heading' },
};

interface TextProps {
  variant?: TextVariant;
  className?: ClassValue;
  children: ReactNode;
}

const Text: React.FC<TextProps> = ({
  variant = 'body',
  className,
  children,
}) => {
  const { className: variantStyle } = variantStyles[variant];
  const styles = cn(variantStyle, className);

  return <p className={styles}>{children}</p>;
};

export default Text;
