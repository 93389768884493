import { FC } from 'react';

export interface LogoProps {
  variant?: 'icon' | 'name' | 'stacked' | 'name-png' | 'name-white-png';
  color?: 'light' | 'dark';
  enableLink?: boolean;
  className?: string;
}

import { cn } from '@shared/lib/utils';
import { Link } from 'react-router-dom';
import iconLogoWhite from './assets/logo-icon-white.svg';
import iconLogo from './assets/logo-icon.svg';
import nameLogoWhitePng from './assets/logo-name-white.png';
import nameLogoWhite from './assets/logo-name-white.svg';
import nameLogoPng from './assets/logo-name.png';
import nameLogo from './assets/logo-name.svg';
import stackedLogoWhite from './assets/logo-stacked-white.svg';
import stackedLogo from './assets/logo-stacked.svg';

const Logo: FC<LogoProps> = ({
  variant = 'icon',
  className,
  color = 'dark',
  enableLink = false,
}) => {
  let img = '';

  switch (variant) {
    case 'icon':
      color === 'dark' ? (img = iconLogo) : (img = iconLogoWhite);
      break;
    case 'name':
      color === 'dark' ? (img = nameLogo) : (img = nameLogoWhite);
      break;
    case 'stacked':
      color === 'dark' ? (img = stackedLogo) : (img = stackedLogoWhite);
      break;
    case 'name-png':
      img = nameLogoPng;
      break;
    case 'name-white-png':
      img = nameLogoWhitePng;
      break;
    default:
      img = nameLogo;
      break;
  }

  if (enableLink) {
    return (
      <Link to="/">
        <img className={cn('', className)} src={img} alt="My Emissions logo" />
      </Link>
    );
  }

  return (
    <img className={cn('', className)} src={img} alt="My Emissions logo" />
  );
};

export default Logo;
