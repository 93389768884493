import { handleLeadingZeros } from '@app/utils/helpers';
import {
  JourneyGroupCreate,
  PackagingTypeCreate,
  ProcessingTypeCreate,
} from '@shared/api/types';
import sentry from '@shared/services/sentry';
import { QueryStatus } from '@tanstack/react-query';
import axios from 'axios';
import { clsx, type ClassValue } from 'clsx';
import { format } from 'date-fns';
import { twMerge } from 'tailwind-merge';
import { CLIENT_READABLE_TEXT } from './constants';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getClientReadableName(text: string) {
  return CLIENT_READABLE_TEXT[text];
}

export function getRelativeDateStatus(date: Date, days: number) {
  const today = new Date();
  const deadline = new Date(date);

  const timeDiff = deadline.getTime() - today.getTime();
  const daysDiff = timeDiff / (1000 * 3600 * 24);

  switch (true) {
    case daysDiff < 0:
      return 'Overdue';
    case daysDiff <= days:
      return 'Due soon';
    default:
      return 'Due later';
  }
}

export const capitalizeFirstLetter = (text: string) => {
  return text[0].toUpperCase() + text.substring(1);
};

export function removeUnderScore(text: string) {
  return text.replace('_', ' ');
}

export function removeDash(text: string) {
  return text.replaceAll('-', ' ');
}

export function formatUnderscoreText(text: string) {
  const removeUnderScoreValue = removeUnderScore(text);
  return capitalizeFirstLetter(removeUnderScoreValue);
}

export function roundNumberToNDecimalPlaces(
  number: number,
  numDecimalPlaces: number = 1,
  addTrailingZero: boolean = true
): number {
  const factorOfTen = Math.pow(10, numDecimalPlaces);
  let result = Math.round(number * factorOfTen) / factorOfTen;
  if (addTrailingZero) {
    result = handleLeadingZeros(result, numDecimalPlaces);
  }
  return result;
}

//example input: 2022-02-17
//example output: 17 Feb 2022
export function getUserFriendlyDate(inputDate: string) {
  // Split the input date by "-"
  const parts = inputDate.split('-');

  // Parse year, month, and day
  const year = parts[0];
  const month = parseInt(parts[1], 10);
  const day = parseInt(parts[2], 10);

  // Create an array of month names
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  // Format the date
  const formattedDate = `${day} ${monthNames[month - 1]} ${year}`;

  return formattedDate;
}

// utility function to get overall status of multiple queries.
// input: a list of query statuses (NB: QueryStatus is of type "loading" | "error" | "success")
export const getOverallStatus = (statuses: QueryStatus[]) => {
  if (statuses.some((status) => status === 'error')) {
    return 'error';
  } else if (statuses.some((status) => status === 'loading')) {
    return 'loading';
  } else {
    return 'success';
  }
};

export const uploadFile = async ({
  data,
  file,
}: {
  data: PackagingTypeCreate | ProcessingTypeCreate | JourneyGroupCreate;
  file: File;
}) => {
  const postData = new FormData();
  for (const key in data?.fields) {
    postData.append(key, data.fields[key] as string);
  }

  postData.append('file', file);

  try {
    const response = await axios.post(data.url, postData, {});
    return { fileId: data.uuid, status: response.status };
  } catch (error) {
    sentry.log(error);
  }
};
export const convertDate = (date: string) => {
  const dateArr = date.split('-');
  const month = dateArr[1];
  const day = dateArr[2];
  const year = dateArr[0];
  const yearShort = year.slice(2);

  const monthName = new Date(2021, +month - 1, +day).toLocaleString('default', {
    month: 'short',
  });

  return `${monthName} ${yearShort}`;
};

export const RatingToTextEnum = {
  A: 'Very low',
  B: 'Low',
  C: 'Medium',
  D: 'High',
  E: 'Very high',
};

export function getObjectFromArrayWithKey<T>(
  key: keyof T,
  value: string | number | boolean,
  array: T[]
): T | null {
  return array.find((item) => item[key] === value) || null;
}

export function formatDate(
  date: Date | string,
  formatString: string = 'do MMMM yyyy'
) {
  const formattedDate = format(date, formatString);
  return formattedDate;
}

export function downloadCSV(csv: string, filename: string) {
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}
