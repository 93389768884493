/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * My Emissions API
 * We calculate food carbon footprints
 * OpenAPI spec version: 1.0.0 (v1)
 */
import {
  useInfiniteQuery,
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  ActivityFuel,
  ActivityGas,
  ActivityImportBase,
  ActivityImportFood,
  ActivityImportFoodUpload,
  ActivityImportRetrieve,
  ActivityUnit,
  ActivityUpdateResponse,
  CompanyReport,
  CompanyReportCategoryStatus,
  CompanyReportDetail,
  CompanyReportResult,
  CompanyReportsActivitiesDirectGasEmissionsListParams,
  CompanyReportsActivitiesDirectGasEmissionsRetrieveParams,
  CompanyReportsActivitiesElectricityListParams,
  CompanyReportsActivitiesElectricityRetrieveParams,
  CompanyReportsActivitiesFoodListParams,
  CompanyReportsActivitiesFoodRetrieveParams,
  CompanyReportsActivitiesHeatListParams,
  CompanyReportsActivitiesHeatRetrieveParams,
  CompanyReportsActivitiesMobileCombustionListParams,
  CompanyReportsActivitiesMobileCombustionRetrieveParams,
  CompanyReportsActivitiesStationaryCombustionListParams,
  CompanyReportsActivitiesStationaryCombustionRetrieveParams,
  CompanyReportsActivityTypesUnitsListParams,
  CompanyReportsFuelsListParams,
  CompanyReportsGasesListParams,
  CompanyReportsImportsListParams,
  CompanyReportsListParams,
  CompanyReportsSitesElectricitySuppliesListParams,
  CompanyReportsSitesListParams,
  DirectGasEmissionActivityCreate,
  DirectGasEmissionActivityRetrieve,
  DirectGasEmissionActivityUpdate,
  ElectricityActivityCreate,
  ElectricityActivityRetrieve,
  ElectricityActivityUpdate,
  ElectricitySupply,
  FoodActivityRetrieve,
  FoodActivityUpdate,
  HeatActivityCreate,
  HeatActivityRetrieve,
  HeatActivityUpdate,
  MobileCombustionActivityCreate,
  MobileCombustionActivityRetrieve,
  MobileCombustionActivityUpdate,
  PaginatedActivityImportListList,
  PaginatedCompanyReportList,
  PaginatedDirectGasEmissionActivitiesListList,
  PaginatedElectricityActivitiesListList,
  PaginatedElectricitySupplyList,
  PaginatedFoodActivitiesListList,
  PaginatedHeatActivitiesListList,
  PaginatedMobileCombustionActivitiesListList,
  PaginatedSiteList,
  PaginatedStationaryCombustionActivitiesListList,
  PatchedElectricitySupply,
  PatchedSite,
  ProcessFoodRows,
  Site,
  StationaryCombustionActivityCreate,
  StationaryCombustionActivityRetrieve,
  StationaryCombustionActivityUpdate,
  TimePeriod
} from '../../types'
import { apiClient } from '../../client';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const companyReportsList = (
    params?: CompanyReportsListParams,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<PaginatedCompanyReportList>(
      {url: `/company-reports/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getCompanyReportsListQueryKey = (params?: CompanyReportsListParams,) => {
    return [`/company-reports/`, ...(params ? [params]: [])] as const;
    }

    
export const getCompanyReportsListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsList>>, TError = unknown>(params?: CompanyReportsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsList>>> = ({ signal, pageParam }) => companyReportsList({...params, page: pageParam || params?.['page']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsList>>>
export type CompanyReportsListInfiniteQueryError = unknown

export const useCompanyReportsListInfinite = <TData = Awaited<ReturnType<typeof companyReportsList>>, TError = unknown>(
 params?: CompanyReportsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsListInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsListQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsList>>, TError = unknown>(params?: CompanyReportsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsList>>> = ({ signal }) => companyReportsList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsListQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsList>>>
export type CompanyReportsListQueryError = unknown

export const useCompanyReportsList = <TData = Awaited<ReturnType<typeof companyReportsList>>, TError = unknown>(
 params?: CompanyReportsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsCreate = (
    companyReport: NonReadonly<CompanyReport>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<CompanyReport>(
      {url: `/company-reports/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: companyReport
    },
      options);
    }
  


export const getCompanyReportsCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsCreate>>, TError,{data: NonReadonly<CompanyReport>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsCreate>>, TError,{data: NonReadonly<CompanyReport>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsCreate>>, {data: NonReadonly<CompanyReport>}> = (props) => {
          const {data} = props ?? {};

          return  companyReportsCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsCreate>>>
    export type CompanyReportsCreateMutationBody = NonReadonly<CompanyReport>
    export type CompanyReportsCreateMutationError = unknown

    export const useCompanyReportsCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsCreate>>, TError,{data: NonReadonly<CompanyReport>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsCreate>>,
        TError,
        {data: NonReadonly<CompanyReport>},
        TContext
      > => {

      const mutationOptions = getCompanyReportsCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsRetrieve = (
    companyReportUuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<CompanyReportDetail>(
      {url: `/company-reports/${companyReportUuid}`, method: 'GET', signal
    },
      options);
    }
  

export const getCompanyReportsRetrieveQueryKey = (companyReportUuid: string,) => {
    return [`/company-reports/${companyReportUuid}`] as const;
    }

    
export const getCompanyReportsRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsRetrieve>>, TError = unknown>(companyReportUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsRetrieveQueryKey(companyReportUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsRetrieve>>> = ({ signal }) => companyReportsRetrieve(companyReportUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsRetrieve>>>
export type CompanyReportsRetrieveInfiniteQueryError = unknown

export const useCompanyReportsRetrieveInfinite = <TData = Awaited<ReturnType<typeof companyReportsRetrieve>>, TError = unknown>(
 companyReportUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsRetrieveInfiniteQueryOptions(companyReportUuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsRetrieve>>, TError = unknown>(companyReportUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsRetrieveQueryKey(companyReportUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsRetrieve>>> = ({ signal }) => companyReportsRetrieve(companyReportUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsRetrieve>>>
export type CompanyReportsRetrieveQueryError = unknown

export const useCompanyReportsRetrieve = <TData = Awaited<ReturnType<typeof companyReportsRetrieve>>, TError = unknown>(
 companyReportUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsRetrieveQueryOptions(companyReportUuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsActivitiesDestroy = (
    companyReportUuid: string,
    activityUuid: string,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<void>(
      {url: `/company-reports/${companyReportUuid}/activities/${activityUuid}`, method: 'DELETE'
    },
      options);
    }
  


export const getCompanyReportsActivitiesDestroyMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesDestroy>>, TError,{companyReportUuid: string;activityUuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesDestroy>>, TError,{companyReportUuid: string;activityUuid: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsActivitiesDestroy>>, {companyReportUuid: string;activityUuid: string}> = (props) => {
          const {companyReportUuid,activityUuid} = props ?? {};

          return  companyReportsActivitiesDestroy(companyReportUuid,activityUuid,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsActivitiesDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesDestroy>>>
    
    export type CompanyReportsActivitiesDestroyMutationError = unknown

    export const useCompanyReportsActivitiesDestroy = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesDestroy>>, TError,{companyReportUuid: string;activityUuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsActivitiesDestroy>>,
        TError,
        {companyReportUuid: string;activityUuid: string},
        TContext
      > => {

      const mutationOptions = getCompanyReportsActivitiesDestroyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsActivitiesDirectGasEmissionsList = (
    companyReportUuid: string,
    params?: CompanyReportsActivitiesDirectGasEmissionsListParams,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<PaginatedDirectGasEmissionActivitiesListList>(
      {url: `/company-reports/${companyReportUuid}/activities/direct-gas-emissions`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getCompanyReportsActivitiesDirectGasEmissionsListQueryKey = (companyReportUuid: string,
    params?: CompanyReportsActivitiesDirectGasEmissionsListParams,) => {
    return [`/company-reports/${companyReportUuid}/activities/direct-gas-emissions`, ...(params ? [params]: [])] as const;
    }

    
export const getCompanyReportsActivitiesDirectGasEmissionsListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsList>>, TError = unknown>(companyReportUuid: string,
    params?: CompanyReportsActivitiesDirectGasEmissionsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsActivitiesDirectGasEmissionsListQueryKey(companyReportUuid,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsList>>> = ({ signal, pageParam }) => companyReportsActivitiesDirectGasEmissionsList(companyReportUuid,{...params, page: pageParam || params?.['page']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsActivitiesDirectGasEmissionsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsList>>>
export type CompanyReportsActivitiesDirectGasEmissionsListInfiniteQueryError = unknown

export const useCompanyReportsActivitiesDirectGasEmissionsListInfinite = <TData = Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsList>>, TError = unknown>(
 companyReportUuid: string,
    params?: CompanyReportsActivitiesDirectGasEmissionsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsActivitiesDirectGasEmissionsListInfiniteQueryOptions(companyReportUuid,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsActivitiesDirectGasEmissionsListQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsList>>, TError = unknown>(companyReportUuid: string,
    params?: CompanyReportsActivitiesDirectGasEmissionsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsActivitiesDirectGasEmissionsListQueryKey(companyReportUuid,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsList>>> = ({ signal }) => companyReportsActivitiesDirectGasEmissionsList(companyReportUuid,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsActivitiesDirectGasEmissionsListQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsList>>>
export type CompanyReportsActivitiesDirectGasEmissionsListQueryError = unknown

export const useCompanyReportsActivitiesDirectGasEmissionsList = <TData = Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsList>>, TError = unknown>(
 companyReportUuid: string,
    params?: CompanyReportsActivitiesDirectGasEmissionsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsActivitiesDirectGasEmissionsListQueryOptions(companyReportUuid,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsActivitiesDirectGasEmissionsCreate = (
    companyReportUuid: string,
    directGasEmissionActivityCreate: NonReadonly<DirectGasEmissionActivityCreate>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<DirectGasEmissionActivityCreate>(
      {url: `/company-reports/${companyReportUuid}/activities/direct-gas-emissions`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: directGasEmissionActivityCreate
    },
      options);
    }
  


export const getCompanyReportsActivitiesDirectGasEmissionsCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsCreate>>, TError,{companyReportUuid: string;data: NonReadonly<DirectGasEmissionActivityCreate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsCreate>>, TError,{companyReportUuid: string;data: NonReadonly<DirectGasEmissionActivityCreate>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsCreate>>, {companyReportUuid: string;data: NonReadonly<DirectGasEmissionActivityCreate>}> = (props) => {
          const {companyReportUuid,data} = props ?? {};

          return  companyReportsActivitiesDirectGasEmissionsCreate(companyReportUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsActivitiesDirectGasEmissionsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsCreate>>>
    export type CompanyReportsActivitiesDirectGasEmissionsCreateMutationBody = NonReadonly<DirectGasEmissionActivityCreate>
    export type CompanyReportsActivitiesDirectGasEmissionsCreateMutationError = unknown

    export const useCompanyReportsActivitiesDirectGasEmissionsCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsCreate>>, TError,{companyReportUuid: string;data: NonReadonly<DirectGasEmissionActivityCreate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsCreate>>,
        TError,
        {companyReportUuid: string;data: NonReadonly<DirectGasEmissionActivityCreate>},
        TContext
      > => {

      const mutationOptions = getCompanyReportsActivitiesDirectGasEmissionsCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsActivitiesDirectGasEmissionsRetrieve = (
    companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesDirectGasEmissionsRetrieveParams,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<DirectGasEmissionActivityRetrieve>(
      {url: `/company-reports/${companyReportUuid}/activities/direct-gas-emissions/${activityUuid}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getCompanyReportsActivitiesDirectGasEmissionsRetrieveQueryKey = (companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesDirectGasEmissionsRetrieveParams,) => {
    return [`/company-reports/${companyReportUuid}/activities/direct-gas-emissions/${activityUuid}`, ...(params ? [params]: [])] as const;
    }

    
export const getCompanyReportsActivitiesDirectGasEmissionsRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsRetrieve>>, TError = unknown>(companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesDirectGasEmissionsRetrieveParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsActivitiesDirectGasEmissionsRetrieveQueryKey(companyReportUuid,activityUuid,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsRetrieve>>> = ({ signal, pageParam }) => companyReportsActivitiesDirectGasEmissionsRetrieve(companyReportUuid,activityUuid,{...params, page: pageParam || params?.['page']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid && activityUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsActivitiesDirectGasEmissionsRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsRetrieve>>>
export type CompanyReportsActivitiesDirectGasEmissionsRetrieveInfiniteQueryError = unknown

export const useCompanyReportsActivitiesDirectGasEmissionsRetrieveInfinite = <TData = Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsRetrieve>>, TError = unknown>(
 companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesDirectGasEmissionsRetrieveParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsActivitiesDirectGasEmissionsRetrieveInfiniteQueryOptions(companyReportUuid,activityUuid,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsActivitiesDirectGasEmissionsRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsRetrieve>>, TError = unknown>(companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesDirectGasEmissionsRetrieveParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsActivitiesDirectGasEmissionsRetrieveQueryKey(companyReportUuid,activityUuid,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsRetrieve>>> = ({ signal }) => companyReportsActivitiesDirectGasEmissionsRetrieve(companyReportUuid,activityUuid,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid && activityUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsActivitiesDirectGasEmissionsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsRetrieve>>>
export type CompanyReportsActivitiesDirectGasEmissionsRetrieveQueryError = unknown

export const useCompanyReportsActivitiesDirectGasEmissionsRetrieve = <TData = Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsRetrieve>>, TError = unknown>(
 companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesDirectGasEmissionsRetrieveParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsActivitiesDirectGasEmissionsRetrieveQueryOptions(companyReportUuid,activityUuid,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsActivitiesDirectGasEmissionsUpdate = (
    companyReportUuid: string,
    activityUuid: string,
    directGasEmissionActivityUpdate: NonReadonly<DirectGasEmissionActivityUpdate>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ActivityUpdateResponse>(
      {url: `/company-reports/${companyReportUuid}/activities/direct-gas-emissions/${activityUuid}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: directGasEmissionActivityUpdate
    },
      options);
    }
  


export const getCompanyReportsActivitiesDirectGasEmissionsUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsUpdate>>, TError,{companyReportUuid: string;activityUuid: string;data: NonReadonly<DirectGasEmissionActivityUpdate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsUpdate>>, TError,{companyReportUuid: string;activityUuid: string;data: NonReadonly<DirectGasEmissionActivityUpdate>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsUpdate>>, {companyReportUuid: string;activityUuid: string;data: NonReadonly<DirectGasEmissionActivityUpdate>}> = (props) => {
          const {companyReportUuid,activityUuid,data} = props ?? {};

          return  companyReportsActivitiesDirectGasEmissionsUpdate(companyReportUuid,activityUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsActivitiesDirectGasEmissionsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsUpdate>>>
    export type CompanyReportsActivitiesDirectGasEmissionsUpdateMutationBody = NonReadonly<DirectGasEmissionActivityUpdate>
    export type CompanyReportsActivitiesDirectGasEmissionsUpdateMutationError = unknown

    export const useCompanyReportsActivitiesDirectGasEmissionsUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsUpdate>>, TError,{companyReportUuid: string;activityUuid: string;data: NonReadonly<DirectGasEmissionActivityUpdate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsActivitiesDirectGasEmissionsUpdate>>,
        TError,
        {companyReportUuid: string;activityUuid: string;data: NonReadonly<DirectGasEmissionActivityUpdate>},
        TContext
      > => {

      const mutationOptions = getCompanyReportsActivitiesDirectGasEmissionsUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsActivitiesElectricityList = (
    companyReportUuid: string,
    params?: CompanyReportsActivitiesElectricityListParams,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<PaginatedElectricityActivitiesListList>(
      {url: `/company-reports/${companyReportUuid}/activities/electricity`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getCompanyReportsActivitiesElectricityListQueryKey = (companyReportUuid: string,
    params?: CompanyReportsActivitiesElectricityListParams,) => {
    return [`/company-reports/${companyReportUuid}/activities/electricity`, ...(params ? [params]: [])] as const;
    }

    
export const getCompanyReportsActivitiesElectricityListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsActivitiesElectricityList>>, TError = unknown>(companyReportUuid: string,
    params?: CompanyReportsActivitiesElectricityListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesElectricityList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsActivitiesElectricityListQueryKey(companyReportUuid,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsActivitiesElectricityList>>> = ({ signal, pageParam }) => companyReportsActivitiesElectricityList(companyReportUuid,{...params, page: pageParam || params?.['page']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesElectricityList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsActivitiesElectricityListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesElectricityList>>>
export type CompanyReportsActivitiesElectricityListInfiniteQueryError = unknown

export const useCompanyReportsActivitiesElectricityListInfinite = <TData = Awaited<ReturnType<typeof companyReportsActivitiesElectricityList>>, TError = unknown>(
 companyReportUuid: string,
    params?: CompanyReportsActivitiesElectricityListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesElectricityList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsActivitiesElectricityListInfiniteQueryOptions(companyReportUuid,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsActivitiesElectricityListQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsActivitiesElectricityList>>, TError = unknown>(companyReportUuid: string,
    params?: CompanyReportsActivitiesElectricityListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesElectricityList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsActivitiesElectricityListQueryKey(companyReportUuid,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsActivitiesElectricityList>>> = ({ signal }) => companyReportsActivitiesElectricityList(companyReportUuid,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesElectricityList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsActivitiesElectricityListQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesElectricityList>>>
export type CompanyReportsActivitiesElectricityListQueryError = unknown

export const useCompanyReportsActivitiesElectricityList = <TData = Awaited<ReturnType<typeof companyReportsActivitiesElectricityList>>, TError = unknown>(
 companyReportUuid: string,
    params?: CompanyReportsActivitiesElectricityListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesElectricityList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsActivitiesElectricityListQueryOptions(companyReportUuid,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsActivitiesElectricityCreate = (
    companyReportUuid: string,
    electricityActivityCreate: NonReadonly<ElectricityActivityCreate>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ElectricityActivityCreate>(
      {url: `/company-reports/${companyReportUuid}/activities/electricity`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: electricityActivityCreate
    },
      options);
    }
  


export const getCompanyReportsActivitiesElectricityCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesElectricityCreate>>, TError,{companyReportUuid: string;data: NonReadonly<ElectricityActivityCreate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesElectricityCreate>>, TError,{companyReportUuid: string;data: NonReadonly<ElectricityActivityCreate>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsActivitiesElectricityCreate>>, {companyReportUuid: string;data: NonReadonly<ElectricityActivityCreate>}> = (props) => {
          const {companyReportUuid,data} = props ?? {};

          return  companyReportsActivitiesElectricityCreate(companyReportUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsActivitiesElectricityCreateMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesElectricityCreate>>>
    export type CompanyReportsActivitiesElectricityCreateMutationBody = NonReadonly<ElectricityActivityCreate>
    export type CompanyReportsActivitiesElectricityCreateMutationError = unknown

    export const useCompanyReportsActivitiesElectricityCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesElectricityCreate>>, TError,{companyReportUuid: string;data: NonReadonly<ElectricityActivityCreate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsActivitiesElectricityCreate>>,
        TError,
        {companyReportUuid: string;data: NonReadonly<ElectricityActivityCreate>},
        TContext
      > => {

      const mutationOptions = getCompanyReportsActivitiesElectricityCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsActivitiesElectricityRetrieve = (
    companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesElectricityRetrieveParams,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<ElectricityActivityRetrieve>(
      {url: `/company-reports/${companyReportUuid}/activities/electricity/${activityUuid}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getCompanyReportsActivitiesElectricityRetrieveQueryKey = (companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesElectricityRetrieveParams,) => {
    return [`/company-reports/${companyReportUuid}/activities/electricity/${activityUuid}`, ...(params ? [params]: [])] as const;
    }

    
export const getCompanyReportsActivitiesElectricityRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsActivitiesElectricityRetrieve>>, TError = unknown>(companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesElectricityRetrieveParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesElectricityRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsActivitiesElectricityRetrieveQueryKey(companyReportUuid,activityUuid,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsActivitiesElectricityRetrieve>>> = ({ signal, pageParam }) => companyReportsActivitiesElectricityRetrieve(companyReportUuid,activityUuid,{...params, page: pageParam || params?.['page']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid && activityUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesElectricityRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsActivitiesElectricityRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesElectricityRetrieve>>>
export type CompanyReportsActivitiesElectricityRetrieveInfiniteQueryError = unknown

export const useCompanyReportsActivitiesElectricityRetrieveInfinite = <TData = Awaited<ReturnType<typeof companyReportsActivitiesElectricityRetrieve>>, TError = unknown>(
 companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesElectricityRetrieveParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesElectricityRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsActivitiesElectricityRetrieveInfiniteQueryOptions(companyReportUuid,activityUuid,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsActivitiesElectricityRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsActivitiesElectricityRetrieve>>, TError = unknown>(companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesElectricityRetrieveParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesElectricityRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsActivitiesElectricityRetrieveQueryKey(companyReportUuid,activityUuid,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsActivitiesElectricityRetrieve>>> = ({ signal }) => companyReportsActivitiesElectricityRetrieve(companyReportUuid,activityUuid,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid && activityUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesElectricityRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsActivitiesElectricityRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesElectricityRetrieve>>>
export type CompanyReportsActivitiesElectricityRetrieveQueryError = unknown

export const useCompanyReportsActivitiesElectricityRetrieve = <TData = Awaited<ReturnType<typeof companyReportsActivitiesElectricityRetrieve>>, TError = unknown>(
 companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesElectricityRetrieveParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesElectricityRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsActivitiesElectricityRetrieveQueryOptions(companyReportUuid,activityUuid,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsActivitiesElectricityUpdate = (
    companyReportUuid: string,
    activityUuid: string,
    electricityActivityUpdate: NonReadonly<ElectricityActivityUpdate>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ActivityUpdateResponse>(
      {url: `/company-reports/${companyReportUuid}/activities/electricity/${activityUuid}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: electricityActivityUpdate
    },
      options);
    }
  


export const getCompanyReportsActivitiesElectricityUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesElectricityUpdate>>, TError,{companyReportUuid: string;activityUuid: string;data: NonReadonly<ElectricityActivityUpdate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesElectricityUpdate>>, TError,{companyReportUuid: string;activityUuid: string;data: NonReadonly<ElectricityActivityUpdate>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsActivitiesElectricityUpdate>>, {companyReportUuid: string;activityUuid: string;data: NonReadonly<ElectricityActivityUpdate>}> = (props) => {
          const {companyReportUuid,activityUuid,data} = props ?? {};

          return  companyReportsActivitiesElectricityUpdate(companyReportUuid,activityUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsActivitiesElectricityUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesElectricityUpdate>>>
    export type CompanyReportsActivitiesElectricityUpdateMutationBody = NonReadonly<ElectricityActivityUpdate>
    export type CompanyReportsActivitiesElectricityUpdateMutationError = unknown

    export const useCompanyReportsActivitiesElectricityUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesElectricityUpdate>>, TError,{companyReportUuid: string;activityUuid: string;data: NonReadonly<ElectricityActivityUpdate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsActivitiesElectricityUpdate>>,
        TError,
        {companyReportUuid: string;activityUuid: string;data: NonReadonly<ElectricityActivityUpdate>},
        TContext
      > => {

      const mutationOptions = getCompanyReportsActivitiesElectricityUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsActivitiesFoodList = (
    companyReportUuid: string,
    params?: CompanyReportsActivitiesFoodListParams,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<PaginatedFoodActivitiesListList>(
      {url: `/company-reports/${companyReportUuid}/activities/food`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getCompanyReportsActivitiesFoodListQueryKey = (companyReportUuid: string,
    params?: CompanyReportsActivitiesFoodListParams,) => {
    return [`/company-reports/${companyReportUuid}/activities/food`, ...(params ? [params]: [])] as const;
    }

    
export const getCompanyReportsActivitiesFoodListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsActivitiesFoodList>>, TError = unknown>(companyReportUuid: string,
    params?: CompanyReportsActivitiesFoodListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesFoodList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsActivitiesFoodListQueryKey(companyReportUuid,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsActivitiesFoodList>>> = ({ signal, pageParam }) => companyReportsActivitiesFoodList(companyReportUuid,{...params, page: pageParam || params?.['page']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesFoodList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsActivitiesFoodListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesFoodList>>>
export type CompanyReportsActivitiesFoodListInfiniteQueryError = unknown

export const useCompanyReportsActivitiesFoodListInfinite = <TData = Awaited<ReturnType<typeof companyReportsActivitiesFoodList>>, TError = unknown>(
 companyReportUuid: string,
    params?: CompanyReportsActivitiesFoodListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesFoodList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsActivitiesFoodListInfiniteQueryOptions(companyReportUuid,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsActivitiesFoodListQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsActivitiesFoodList>>, TError = unknown>(companyReportUuid: string,
    params?: CompanyReportsActivitiesFoodListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesFoodList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsActivitiesFoodListQueryKey(companyReportUuid,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsActivitiesFoodList>>> = ({ signal }) => companyReportsActivitiesFoodList(companyReportUuid,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesFoodList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsActivitiesFoodListQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesFoodList>>>
export type CompanyReportsActivitiesFoodListQueryError = unknown

export const useCompanyReportsActivitiesFoodList = <TData = Awaited<ReturnType<typeof companyReportsActivitiesFoodList>>, TError = unknown>(
 companyReportUuid: string,
    params?: CompanyReportsActivitiesFoodListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesFoodList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsActivitiesFoodListQueryOptions(companyReportUuid,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsActivitiesFoodCreate = (
    companyReportUuid: string,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<void>(
      {url: `/company-reports/${companyReportUuid}/activities/food`, method: 'POST'
    },
      options);
    }
  


export const getCompanyReportsActivitiesFoodCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesFoodCreate>>, TError,{companyReportUuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesFoodCreate>>, TError,{companyReportUuid: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsActivitiesFoodCreate>>, {companyReportUuid: string}> = (props) => {
          const {companyReportUuid} = props ?? {};

          return  companyReportsActivitiesFoodCreate(companyReportUuid,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsActivitiesFoodCreateMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesFoodCreate>>>
    
    export type CompanyReportsActivitiesFoodCreateMutationError = unknown

    export const useCompanyReportsActivitiesFoodCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesFoodCreate>>, TError,{companyReportUuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsActivitiesFoodCreate>>,
        TError,
        {companyReportUuid: string},
        TContext
      > => {

      const mutationOptions = getCompanyReportsActivitiesFoodCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsActivitiesFoodRetrieve = (
    companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesFoodRetrieveParams,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<FoodActivityRetrieve>(
      {url: `/company-reports/${companyReportUuid}/activities/food/${activityUuid}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getCompanyReportsActivitiesFoodRetrieveQueryKey = (companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesFoodRetrieveParams,) => {
    return [`/company-reports/${companyReportUuid}/activities/food/${activityUuid}`, ...(params ? [params]: [])] as const;
    }

    
export const getCompanyReportsActivitiesFoodRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsActivitiesFoodRetrieve>>, TError = unknown>(companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesFoodRetrieveParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesFoodRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsActivitiesFoodRetrieveQueryKey(companyReportUuid,activityUuid,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsActivitiesFoodRetrieve>>> = ({ signal, pageParam }) => companyReportsActivitiesFoodRetrieve(companyReportUuid,activityUuid,{...params, page: pageParam || params?.['page']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid && activityUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesFoodRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsActivitiesFoodRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesFoodRetrieve>>>
export type CompanyReportsActivitiesFoodRetrieveInfiniteQueryError = unknown

export const useCompanyReportsActivitiesFoodRetrieveInfinite = <TData = Awaited<ReturnType<typeof companyReportsActivitiesFoodRetrieve>>, TError = unknown>(
 companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesFoodRetrieveParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesFoodRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsActivitiesFoodRetrieveInfiniteQueryOptions(companyReportUuid,activityUuid,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsActivitiesFoodRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsActivitiesFoodRetrieve>>, TError = unknown>(companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesFoodRetrieveParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesFoodRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsActivitiesFoodRetrieveQueryKey(companyReportUuid,activityUuid,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsActivitiesFoodRetrieve>>> = ({ signal }) => companyReportsActivitiesFoodRetrieve(companyReportUuid,activityUuid,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid && activityUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesFoodRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsActivitiesFoodRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesFoodRetrieve>>>
export type CompanyReportsActivitiesFoodRetrieveQueryError = unknown

export const useCompanyReportsActivitiesFoodRetrieve = <TData = Awaited<ReturnType<typeof companyReportsActivitiesFoodRetrieve>>, TError = unknown>(
 companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesFoodRetrieveParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesFoodRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsActivitiesFoodRetrieveQueryOptions(companyReportUuid,activityUuid,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsActivitiesFoodUpdate = (
    companyReportUuid: string,
    activityUuid: string,
    foodActivityUpdate: NonReadonly<FoodActivityUpdate>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ActivityUpdateResponse>(
      {url: `/company-reports/${companyReportUuid}/activities/food/${activityUuid}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: foodActivityUpdate
    },
      options);
    }
  


export const getCompanyReportsActivitiesFoodUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesFoodUpdate>>, TError,{companyReportUuid: string;activityUuid: string;data: NonReadonly<FoodActivityUpdate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesFoodUpdate>>, TError,{companyReportUuid: string;activityUuid: string;data: NonReadonly<FoodActivityUpdate>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsActivitiesFoodUpdate>>, {companyReportUuid: string;activityUuid: string;data: NonReadonly<FoodActivityUpdate>}> = (props) => {
          const {companyReportUuid,activityUuid,data} = props ?? {};

          return  companyReportsActivitiesFoodUpdate(companyReportUuid,activityUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsActivitiesFoodUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesFoodUpdate>>>
    export type CompanyReportsActivitiesFoodUpdateMutationBody = NonReadonly<FoodActivityUpdate>
    export type CompanyReportsActivitiesFoodUpdateMutationError = unknown

    export const useCompanyReportsActivitiesFoodUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesFoodUpdate>>, TError,{companyReportUuid: string;activityUuid: string;data: NonReadonly<FoodActivityUpdate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsActivitiesFoodUpdate>>,
        TError,
        {companyReportUuid: string;activityUuid: string;data: NonReadonly<FoodActivityUpdate>},
        TContext
      > => {

      const mutationOptions = getCompanyReportsActivitiesFoodUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsActivitiesHeatList = (
    companyReportUuid: string,
    params?: CompanyReportsActivitiesHeatListParams,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<PaginatedHeatActivitiesListList>(
      {url: `/company-reports/${companyReportUuid}/activities/heat`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getCompanyReportsActivitiesHeatListQueryKey = (companyReportUuid: string,
    params?: CompanyReportsActivitiesHeatListParams,) => {
    return [`/company-reports/${companyReportUuid}/activities/heat`, ...(params ? [params]: [])] as const;
    }

    
export const getCompanyReportsActivitiesHeatListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsActivitiesHeatList>>, TError = unknown>(companyReportUuid: string,
    params?: CompanyReportsActivitiesHeatListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesHeatList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsActivitiesHeatListQueryKey(companyReportUuid,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsActivitiesHeatList>>> = ({ signal, pageParam }) => companyReportsActivitiesHeatList(companyReportUuid,{...params, page: pageParam || params?.['page']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesHeatList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsActivitiesHeatListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesHeatList>>>
export type CompanyReportsActivitiesHeatListInfiniteQueryError = unknown

export const useCompanyReportsActivitiesHeatListInfinite = <TData = Awaited<ReturnType<typeof companyReportsActivitiesHeatList>>, TError = unknown>(
 companyReportUuid: string,
    params?: CompanyReportsActivitiesHeatListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesHeatList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsActivitiesHeatListInfiniteQueryOptions(companyReportUuid,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsActivitiesHeatListQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsActivitiesHeatList>>, TError = unknown>(companyReportUuid: string,
    params?: CompanyReportsActivitiesHeatListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesHeatList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsActivitiesHeatListQueryKey(companyReportUuid,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsActivitiesHeatList>>> = ({ signal }) => companyReportsActivitiesHeatList(companyReportUuid,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesHeatList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsActivitiesHeatListQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesHeatList>>>
export type CompanyReportsActivitiesHeatListQueryError = unknown

export const useCompanyReportsActivitiesHeatList = <TData = Awaited<ReturnType<typeof companyReportsActivitiesHeatList>>, TError = unknown>(
 companyReportUuid: string,
    params?: CompanyReportsActivitiesHeatListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesHeatList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsActivitiesHeatListQueryOptions(companyReportUuid,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsActivitiesHeatCreate = (
    companyReportUuid: string,
    heatActivityCreate: NonReadonly<HeatActivityCreate>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<HeatActivityCreate>(
      {url: `/company-reports/${companyReportUuid}/activities/heat`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: heatActivityCreate
    },
      options);
    }
  


export const getCompanyReportsActivitiesHeatCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesHeatCreate>>, TError,{companyReportUuid: string;data: NonReadonly<HeatActivityCreate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesHeatCreate>>, TError,{companyReportUuid: string;data: NonReadonly<HeatActivityCreate>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsActivitiesHeatCreate>>, {companyReportUuid: string;data: NonReadonly<HeatActivityCreate>}> = (props) => {
          const {companyReportUuid,data} = props ?? {};

          return  companyReportsActivitiesHeatCreate(companyReportUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsActivitiesHeatCreateMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesHeatCreate>>>
    export type CompanyReportsActivitiesHeatCreateMutationBody = NonReadonly<HeatActivityCreate>
    export type CompanyReportsActivitiesHeatCreateMutationError = unknown

    export const useCompanyReportsActivitiesHeatCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesHeatCreate>>, TError,{companyReportUuid: string;data: NonReadonly<HeatActivityCreate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsActivitiesHeatCreate>>,
        TError,
        {companyReportUuid: string;data: NonReadonly<HeatActivityCreate>},
        TContext
      > => {

      const mutationOptions = getCompanyReportsActivitiesHeatCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsActivitiesHeatRetrieve = (
    companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesHeatRetrieveParams,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<HeatActivityRetrieve>(
      {url: `/company-reports/${companyReportUuid}/activities/heat/${activityUuid}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getCompanyReportsActivitiesHeatRetrieveQueryKey = (companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesHeatRetrieveParams,) => {
    return [`/company-reports/${companyReportUuid}/activities/heat/${activityUuid}`, ...(params ? [params]: [])] as const;
    }

    
export const getCompanyReportsActivitiesHeatRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsActivitiesHeatRetrieve>>, TError = unknown>(companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesHeatRetrieveParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesHeatRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsActivitiesHeatRetrieveQueryKey(companyReportUuid,activityUuid,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsActivitiesHeatRetrieve>>> = ({ signal, pageParam }) => companyReportsActivitiesHeatRetrieve(companyReportUuid,activityUuid,{...params, page: pageParam || params?.['page']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid && activityUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesHeatRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsActivitiesHeatRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesHeatRetrieve>>>
export type CompanyReportsActivitiesHeatRetrieveInfiniteQueryError = unknown

export const useCompanyReportsActivitiesHeatRetrieveInfinite = <TData = Awaited<ReturnType<typeof companyReportsActivitiesHeatRetrieve>>, TError = unknown>(
 companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesHeatRetrieveParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesHeatRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsActivitiesHeatRetrieveInfiniteQueryOptions(companyReportUuid,activityUuid,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsActivitiesHeatRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsActivitiesHeatRetrieve>>, TError = unknown>(companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesHeatRetrieveParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesHeatRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsActivitiesHeatRetrieveQueryKey(companyReportUuid,activityUuid,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsActivitiesHeatRetrieve>>> = ({ signal }) => companyReportsActivitiesHeatRetrieve(companyReportUuid,activityUuid,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid && activityUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesHeatRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsActivitiesHeatRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesHeatRetrieve>>>
export type CompanyReportsActivitiesHeatRetrieveQueryError = unknown

export const useCompanyReportsActivitiesHeatRetrieve = <TData = Awaited<ReturnType<typeof companyReportsActivitiesHeatRetrieve>>, TError = unknown>(
 companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesHeatRetrieveParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesHeatRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsActivitiesHeatRetrieveQueryOptions(companyReportUuid,activityUuid,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsActivitiesHeatUpdate = (
    companyReportUuid: string,
    activityUuid: string,
    heatActivityUpdate: NonReadonly<HeatActivityUpdate>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ActivityUpdateResponse>(
      {url: `/company-reports/${companyReportUuid}/activities/heat/${activityUuid}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: heatActivityUpdate
    },
      options);
    }
  


export const getCompanyReportsActivitiesHeatUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesHeatUpdate>>, TError,{companyReportUuid: string;activityUuid: string;data: NonReadonly<HeatActivityUpdate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesHeatUpdate>>, TError,{companyReportUuid: string;activityUuid: string;data: NonReadonly<HeatActivityUpdate>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsActivitiesHeatUpdate>>, {companyReportUuid: string;activityUuid: string;data: NonReadonly<HeatActivityUpdate>}> = (props) => {
          const {companyReportUuid,activityUuid,data} = props ?? {};

          return  companyReportsActivitiesHeatUpdate(companyReportUuid,activityUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsActivitiesHeatUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesHeatUpdate>>>
    export type CompanyReportsActivitiesHeatUpdateMutationBody = NonReadonly<HeatActivityUpdate>
    export type CompanyReportsActivitiesHeatUpdateMutationError = unknown

    export const useCompanyReportsActivitiesHeatUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesHeatUpdate>>, TError,{companyReportUuid: string;activityUuid: string;data: NonReadonly<HeatActivityUpdate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsActivitiesHeatUpdate>>,
        TError,
        {companyReportUuid: string;activityUuid: string;data: NonReadonly<HeatActivityUpdate>},
        TContext
      > => {

      const mutationOptions = getCompanyReportsActivitiesHeatUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsActivitiesMobileCombustionList = (
    companyReportUuid: string,
    params?: CompanyReportsActivitiesMobileCombustionListParams,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<PaginatedMobileCombustionActivitiesListList>(
      {url: `/company-reports/${companyReportUuid}/activities/mobile-combustion`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getCompanyReportsActivitiesMobileCombustionListQueryKey = (companyReportUuid: string,
    params?: CompanyReportsActivitiesMobileCombustionListParams,) => {
    return [`/company-reports/${companyReportUuid}/activities/mobile-combustion`, ...(params ? [params]: [])] as const;
    }

    
export const getCompanyReportsActivitiesMobileCombustionListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionList>>, TError = unknown>(companyReportUuid: string,
    params?: CompanyReportsActivitiesMobileCombustionListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsActivitiesMobileCombustionListQueryKey(companyReportUuid,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionList>>> = ({ signal, pageParam }) => companyReportsActivitiesMobileCombustionList(companyReportUuid,{...params, page: pageParam || params?.['page']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsActivitiesMobileCombustionListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionList>>>
export type CompanyReportsActivitiesMobileCombustionListInfiniteQueryError = unknown

export const useCompanyReportsActivitiesMobileCombustionListInfinite = <TData = Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionList>>, TError = unknown>(
 companyReportUuid: string,
    params?: CompanyReportsActivitiesMobileCombustionListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsActivitiesMobileCombustionListInfiniteQueryOptions(companyReportUuid,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsActivitiesMobileCombustionListQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionList>>, TError = unknown>(companyReportUuid: string,
    params?: CompanyReportsActivitiesMobileCombustionListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsActivitiesMobileCombustionListQueryKey(companyReportUuid,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionList>>> = ({ signal }) => companyReportsActivitiesMobileCombustionList(companyReportUuid,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsActivitiesMobileCombustionListQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionList>>>
export type CompanyReportsActivitiesMobileCombustionListQueryError = unknown

export const useCompanyReportsActivitiesMobileCombustionList = <TData = Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionList>>, TError = unknown>(
 companyReportUuid: string,
    params?: CompanyReportsActivitiesMobileCombustionListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsActivitiesMobileCombustionListQueryOptions(companyReportUuid,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsActivitiesMobileCombustionCreate = (
    companyReportUuid: string,
    mobileCombustionActivityCreate: NonReadonly<MobileCombustionActivityCreate>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<MobileCombustionActivityCreate>(
      {url: `/company-reports/${companyReportUuid}/activities/mobile-combustion`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: mobileCombustionActivityCreate
    },
      options);
    }
  


export const getCompanyReportsActivitiesMobileCombustionCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionCreate>>, TError,{companyReportUuid: string;data: NonReadonly<MobileCombustionActivityCreate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionCreate>>, TError,{companyReportUuid: string;data: NonReadonly<MobileCombustionActivityCreate>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionCreate>>, {companyReportUuid: string;data: NonReadonly<MobileCombustionActivityCreate>}> = (props) => {
          const {companyReportUuid,data} = props ?? {};

          return  companyReportsActivitiesMobileCombustionCreate(companyReportUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsActivitiesMobileCombustionCreateMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionCreate>>>
    export type CompanyReportsActivitiesMobileCombustionCreateMutationBody = NonReadonly<MobileCombustionActivityCreate>
    export type CompanyReportsActivitiesMobileCombustionCreateMutationError = unknown

    export const useCompanyReportsActivitiesMobileCombustionCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionCreate>>, TError,{companyReportUuid: string;data: NonReadonly<MobileCombustionActivityCreate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionCreate>>,
        TError,
        {companyReportUuid: string;data: NonReadonly<MobileCombustionActivityCreate>},
        TContext
      > => {

      const mutationOptions = getCompanyReportsActivitiesMobileCombustionCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsActivitiesMobileCombustionRetrieve = (
    companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesMobileCombustionRetrieveParams,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<MobileCombustionActivityRetrieve>(
      {url: `/company-reports/${companyReportUuid}/activities/mobile-combustion/${activityUuid}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getCompanyReportsActivitiesMobileCombustionRetrieveQueryKey = (companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesMobileCombustionRetrieveParams,) => {
    return [`/company-reports/${companyReportUuid}/activities/mobile-combustion/${activityUuid}`, ...(params ? [params]: [])] as const;
    }

    
export const getCompanyReportsActivitiesMobileCombustionRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionRetrieve>>, TError = unknown>(companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesMobileCombustionRetrieveParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsActivitiesMobileCombustionRetrieveQueryKey(companyReportUuid,activityUuid,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionRetrieve>>> = ({ signal, pageParam }) => companyReportsActivitiesMobileCombustionRetrieve(companyReportUuid,activityUuid,{...params, page: pageParam || params?.['page']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid && activityUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsActivitiesMobileCombustionRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionRetrieve>>>
export type CompanyReportsActivitiesMobileCombustionRetrieveInfiniteQueryError = unknown

export const useCompanyReportsActivitiesMobileCombustionRetrieveInfinite = <TData = Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionRetrieve>>, TError = unknown>(
 companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesMobileCombustionRetrieveParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsActivitiesMobileCombustionRetrieveInfiniteQueryOptions(companyReportUuid,activityUuid,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsActivitiesMobileCombustionRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionRetrieve>>, TError = unknown>(companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesMobileCombustionRetrieveParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsActivitiesMobileCombustionRetrieveQueryKey(companyReportUuid,activityUuid,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionRetrieve>>> = ({ signal }) => companyReportsActivitiesMobileCombustionRetrieve(companyReportUuid,activityUuid,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid && activityUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsActivitiesMobileCombustionRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionRetrieve>>>
export type CompanyReportsActivitiesMobileCombustionRetrieveQueryError = unknown

export const useCompanyReportsActivitiesMobileCombustionRetrieve = <TData = Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionRetrieve>>, TError = unknown>(
 companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesMobileCombustionRetrieveParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsActivitiesMobileCombustionRetrieveQueryOptions(companyReportUuid,activityUuid,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsActivitiesMobileCombustionUpdate = (
    companyReportUuid: string,
    activityUuid: string,
    mobileCombustionActivityUpdate: NonReadonly<MobileCombustionActivityUpdate>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ActivityUpdateResponse>(
      {url: `/company-reports/${companyReportUuid}/activities/mobile-combustion/${activityUuid}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: mobileCombustionActivityUpdate
    },
      options);
    }
  


export const getCompanyReportsActivitiesMobileCombustionUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionUpdate>>, TError,{companyReportUuid: string;activityUuid: string;data: NonReadonly<MobileCombustionActivityUpdate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionUpdate>>, TError,{companyReportUuid: string;activityUuid: string;data: NonReadonly<MobileCombustionActivityUpdate>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionUpdate>>, {companyReportUuid: string;activityUuid: string;data: NonReadonly<MobileCombustionActivityUpdate>}> = (props) => {
          const {companyReportUuid,activityUuid,data} = props ?? {};

          return  companyReportsActivitiesMobileCombustionUpdate(companyReportUuid,activityUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsActivitiesMobileCombustionUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionUpdate>>>
    export type CompanyReportsActivitiesMobileCombustionUpdateMutationBody = NonReadonly<MobileCombustionActivityUpdate>
    export type CompanyReportsActivitiesMobileCombustionUpdateMutationError = unknown

    export const useCompanyReportsActivitiesMobileCombustionUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionUpdate>>, TError,{companyReportUuid: string;activityUuid: string;data: NonReadonly<MobileCombustionActivityUpdate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsActivitiesMobileCombustionUpdate>>,
        TError,
        {companyReportUuid: string;activityUuid: string;data: NonReadonly<MobileCombustionActivityUpdate>},
        TContext
      > => {

      const mutationOptions = getCompanyReportsActivitiesMobileCombustionUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsActivitiesStationaryCombustionList = (
    companyReportUuid: string,
    params?: CompanyReportsActivitiesStationaryCombustionListParams,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<PaginatedStationaryCombustionActivitiesListList>(
      {url: `/company-reports/${companyReportUuid}/activities/stationary-combustion`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getCompanyReportsActivitiesStationaryCombustionListQueryKey = (companyReportUuid: string,
    params?: CompanyReportsActivitiesStationaryCombustionListParams,) => {
    return [`/company-reports/${companyReportUuid}/activities/stationary-combustion`, ...(params ? [params]: [])] as const;
    }

    
export const getCompanyReportsActivitiesStationaryCombustionListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionList>>, TError = unknown>(companyReportUuid: string,
    params?: CompanyReportsActivitiesStationaryCombustionListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsActivitiesStationaryCombustionListQueryKey(companyReportUuid,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionList>>> = ({ signal, pageParam }) => companyReportsActivitiesStationaryCombustionList(companyReportUuid,{...params, page: pageParam || params?.['page']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsActivitiesStationaryCombustionListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionList>>>
export type CompanyReportsActivitiesStationaryCombustionListInfiniteQueryError = unknown

export const useCompanyReportsActivitiesStationaryCombustionListInfinite = <TData = Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionList>>, TError = unknown>(
 companyReportUuid: string,
    params?: CompanyReportsActivitiesStationaryCombustionListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsActivitiesStationaryCombustionListInfiniteQueryOptions(companyReportUuid,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsActivitiesStationaryCombustionListQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionList>>, TError = unknown>(companyReportUuid: string,
    params?: CompanyReportsActivitiesStationaryCombustionListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsActivitiesStationaryCombustionListQueryKey(companyReportUuid,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionList>>> = ({ signal }) => companyReportsActivitiesStationaryCombustionList(companyReportUuid,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsActivitiesStationaryCombustionListQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionList>>>
export type CompanyReportsActivitiesStationaryCombustionListQueryError = unknown

export const useCompanyReportsActivitiesStationaryCombustionList = <TData = Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionList>>, TError = unknown>(
 companyReportUuid: string,
    params?: CompanyReportsActivitiesStationaryCombustionListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsActivitiesStationaryCombustionListQueryOptions(companyReportUuid,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsActivitiesStationaryCombustionCreate = (
    companyReportUuid: string,
    stationaryCombustionActivityCreate: NonReadonly<StationaryCombustionActivityCreate>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<StationaryCombustionActivityCreate>(
      {url: `/company-reports/${companyReportUuid}/activities/stationary-combustion`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: stationaryCombustionActivityCreate
    },
      options);
    }
  


export const getCompanyReportsActivitiesStationaryCombustionCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionCreate>>, TError,{companyReportUuid: string;data: NonReadonly<StationaryCombustionActivityCreate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionCreate>>, TError,{companyReportUuid: string;data: NonReadonly<StationaryCombustionActivityCreate>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionCreate>>, {companyReportUuid: string;data: NonReadonly<StationaryCombustionActivityCreate>}> = (props) => {
          const {companyReportUuid,data} = props ?? {};

          return  companyReportsActivitiesStationaryCombustionCreate(companyReportUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsActivitiesStationaryCombustionCreateMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionCreate>>>
    export type CompanyReportsActivitiesStationaryCombustionCreateMutationBody = NonReadonly<StationaryCombustionActivityCreate>
    export type CompanyReportsActivitiesStationaryCombustionCreateMutationError = unknown

    export const useCompanyReportsActivitiesStationaryCombustionCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionCreate>>, TError,{companyReportUuid: string;data: NonReadonly<StationaryCombustionActivityCreate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionCreate>>,
        TError,
        {companyReportUuid: string;data: NonReadonly<StationaryCombustionActivityCreate>},
        TContext
      > => {

      const mutationOptions = getCompanyReportsActivitiesStationaryCombustionCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsActivitiesStationaryCombustionRetrieve = (
    companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesStationaryCombustionRetrieveParams,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<StationaryCombustionActivityRetrieve>(
      {url: `/company-reports/${companyReportUuid}/activities/stationary-combustion/${activityUuid}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getCompanyReportsActivitiesStationaryCombustionRetrieveQueryKey = (companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesStationaryCombustionRetrieveParams,) => {
    return [`/company-reports/${companyReportUuid}/activities/stationary-combustion/${activityUuid}`, ...(params ? [params]: [])] as const;
    }

    
export const getCompanyReportsActivitiesStationaryCombustionRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionRetrieve>>, TError = unknown>(companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesStationaryCombustionRetrieveParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsActivitiesStationaryCombustionRetrieveQueryKey(companyReportUuid,activityUuid,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionRetrieve>>> = ({ signal, pageParam }) => companyReportsActivitiesStationaryCombustionRetrieve(companyReportUuid,activityUuid,{...params, page: pageParam || params?.['page']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid && activityUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsActivitiesStationaryCombustionRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionRetrieve>>>
export type CompanyReportsActivitiesStationaryCombustionRetrieveInfiniteQueryError = unknown

export const useCompanyReportsActivitiesStationaryCombustionRetrieveInfinite = <TData = Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionRetrieve>>, TError = unknown>(
 companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesStationaryCombustionRetrieveParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsActivitiesStationaryCombustionRetrieveInfiniteQueryOptions(companyReportUuid,activityUuid,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsActivitiesStationaryCombustionRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionRetrieve>>, TError = unknown>(companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesStationaryCombustionRetrieveParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsActivitiesStationaryCombustionRetrieveQueryKey(companyReportUuid,activityUuid,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionRetrieve>>> = ({ signal }) => companyReportsActivitiesStationaryCombustionRetrieve(companyReportUuid,activityUuid,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid && activityUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsActivitiesStationaryCombustionRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionRetrieve>>>
export type CompanyReportsActivitiesStationaryCombustionRetrieveQueryError = unknown

export const useCompanyReportsActivitiesStationaryCombustionRetrieve = <TData = Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionRetrieve>>, TError = unknown>(
 companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesStationaryCombustionRetrieveParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsActivitiesStationaryCombustionRetrieveQueryOptions(companyReportUuid,activityUuid,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsActivitiesStationaryCombustionUpdate = (
    companyReportUuid: string,
    activityUuid: string,
    stationaryCombustionActivityUpdate: NonReadonly<StationaryCombustionActivityUpdate>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ActivityUpdateResponse>(
      {url: `/company-reports/${companyReportUuid}/activities/stationary-combustion/${activityUuid}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: stationaryCombustionActivityUpdate
    },
      options);
    }
  


export const getCompanyReportsActivitiesStationaryCombustionUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionUpdate>>, TError,{companyReportUuid: string;activityUuid: string;data: NonReadonly<StationaryCombustionActivityUpdate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionUpdate>>, TError,{companyReportUuid: string;activityUuid: string;data: NonReadonly<StationaryCombustionActivityUpdate>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionUpdate>>, {companyReportUuid: string;activityUuid: string;data: NonReadonly<StationaryCombustionActivityUpdate>}> = (props) => {
          const {companyReportUuid,activityUuid,data} = props ?? {};

          return  companyReportsActivitiesStationaryCombustionUpdate(companyReportUuid,activityUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsActivitiesStationaryCombustionUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionUpdate>>>
    export type CompanyReportsActivitiesStationaryCombustionUpdateMutationBody = NonReadonly<StationaryCombustionActivityUpdate>
    export type CompanyReportsActivitiesStationaryCombustionUpdateMutationError = unknown

    export const useCompanyReportsActivitiesStationaryCombustionUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionUpdate>>, TError,{companyReportUuid: string;activityUuid: string;data: NonReadonly<StationaryCombustionActivityUpdate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsActivitiesStationaryCombustionUpdate>>,
        TError,
        {companyReportUuid: string;activityUuid: string;data: NonReadonly<StationaryCombustionActivityUpdate>},
        TContext
      > => {

      const mutationOptions = getCompanyReportsActivitiesStationaryCombustionUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsImportsList = (
    companyReportUuid: string,
    params?: CompanyReportsImportsListParams,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<PaginatedActivityImportListList>(
      {url: `/company-reports/${companyReportUuid}/imports`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getCompanyReportsImportsListQueryKey = (companyReportUuid: string,
    params?: CompanyReportsImportsListParams,) => {
    return [`/company-reports/${companyReportUuid}/imports`, ...(params ? [params]: [])] as const;
    }

    
export const getCompanyReportsImportsListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsImportsList>>, TError = unknown>(companyReportUuid: string,
    params?: CompanyReportsImportsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsImportsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsImportsListQueryKey(companyReportUuid,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsImportsList>>> = ({ signal, pageParam }) => companyReportsImportsList(companyReportUuid,{...params, page: pageParam || params?.['page']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsImportsList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsImportsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsImportsList>>>
export type CompanyReportsImportsListInfiniteQueryError = unknown

export const useCompanyReportsImportsListInfinite = <TData = Awaited<ReturnType<typeof companyReportsImportsList>>, TError = unknown>(
 companyReportUuid: string,
    params?: CompanyReportsImportsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsImportsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsImportsListInfiniteQueryOptions(companyReportUuid,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsImportsListQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsImportsList>>, TError = unknown>(companyReportUuid: string,
    params?: CompanyReportsImportsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsImportsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsImportsListQueryKey(companyReportUuid,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsImportsList>>> = ({ signal }) => companyReportsImportsList(companyReportUuid,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsImportsList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsImportsListQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsImportsList>>>
export type CompanyReportsImportsListQueryError = unknown

export const useCompanyReportsImportsList = <TData = Awaited<ReturnType<typeof companyReportsImportsList>>, TError = unknown>(
 companyReportUuid: string,
    params?: CompanyReportsImportsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsImportsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsImportsListQueryOptions(companyReportUuid,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsImportsRetrieve = (
    companyReportUuid: string,
    importUuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<ActivityImportRetrieve>(
      {url: `/company-reports/${companyReportUuid}/imports/${importUuid}`, method: 'GET', signal
    },
      options);
    }
  

export const getCompanyReportsImportsRetrieveQueryKey = (companyReportUuid: string,
    importUuid: string,) => {
    return [`/company-reports/${companyReportUuid}/imports/${importUuid}`] as const;
    }

    
export const getCompanyReportsImportsRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsImportsRetrieve>>, TError = unknown>(companyReportUuid: string,
    importUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsImportsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsImportsRetrieveQueryKey(companyReportUuid,importUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsImportsRetrieve>>> = ({ signal }) => companyReportsImportsRetrieve(companyReportUuid,importUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid && importUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsImportsRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsImportsRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsImportsRetrieve>>>
export type CompanyReportsImportsRetrieveInfiniteQueryError = unknown

export const useCompanyReportsImportsRetrieveInfinite = <TData = Awaited<ReturnType<typeof companyReportsImportsRetrieve>>, TError = unknown>(
 companyReportUuid: string,
    importUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsImportsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsImportsRetrieveInfiniteQueryOptions(companyReportUuid,importUuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsImportsRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsImportsRetrieve>>, TError = unknown>(companyReportUuid: string,
    importUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsImportsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsImportsRetrieveQueryKey(companyReportUuid,importUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsImportsRetrieve>>> = ({ signal }) => companyReportsImportsRetrieve(companyReportUuid,importUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid && importUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsImportsRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsImportsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsImportsRetrieve>>>
export type CompanyReportsImportsRetrieveQueryError = unknown

export const useCompanyReportsImportsRetrieve = <TData = Awaited<ReturnType<typeof companyReportsImportsRetrieve>>, TError = unknown>(
 companyReportUuid: string,
    importUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsImportsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsImportsRetrieveQueryOptions(companyReportUuid,importUuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsImportsDestroy = (
    companyReportUuid: string,
    importUuid: string,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<void>(
      {url: `/company-reports/${companyReportUuid}/imports/${importUuid}`, method: 'DELETE'
    },
      options);
    }
  


export const getCompanyReportsImportsDestroyMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsImportsDestroy>>, TError,{companyReportUuid: string;importUuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsImportsDestroy>>, TError,{companyReportUuid: string;importUuid: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsImportsDestroy>>, {companyReportUuid: string;importUuid: string}> = (props) => {
          const {companyReportUuid,importUuid} = props ?? {};

          return  companyReportsImportsDestroy(companyReportUuid,importUuid,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsImportsDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsImportsDestroy>>>
    
    export type CompanyReportsImportsDestroyMutationError = unknown

    export const useCompanyReportsImportsDestroy = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsImportsDestroy>>, TError,{companyReportUuid: string;importUuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsImportsDestroy>>,
        TError,
        {companyReportUuid: string;importUuid: string},
        TContext
      > => {

      const mutationOptions = getCompanyReportsImportsDestroyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsImportsUploadCreate = (
    companyReportUuid: string,
    importUuid: string,
    activityImportFoodUpload: NonReadonly<ActivityImportFoodUpload>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ActivityImportBase>(
      {url: `/company-reports/${companyReportUuid}/imports/${importUuid}/upload`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: activityImportFoodUpload
    },
      options);
    }
  


export const getCompanyReportsImportsUploadCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsImportsUploadCreate>>, TError,{companyReportUuid: string;importUuid: string;data: NonReadonly<ActivityImportFoodUpload>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsImportsUploadCreate>>, TError,{companyReportUuid: string;importUuid: string;data: NonReadonly<ActivityImportFoodUpload>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsImportsUploadCreate>>, {companyReportUuid: string;importUuid: string;data: NonReadonly<ActivityImportFoodUpload>}> = (props) => {
          const {companyReportUuid,importUuid,data} = props ?? {};

          return  companyReportsImportsUploadCreate(companyReportUuid,importUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsImportsUploadCreateMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsImportsUploadCreate>>>
    export type CompanyReportsImportsUploadCreateMutationBody = NonReadonly<ActivityImportFoodUpload>
    export type CompanyReportsImportsUploadCreateMutationError = unknown

    export const useCompanyReportsImportsUploadCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsImportsUploadCreate>>, TError,{companyReportUuid: string;importUuid: string;data: NonReadonly<ActivityImportFoodUpload>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsImportsUploadCreate>>,
        TError,
        {companyReportUuid: string;importUuid: string;data: NonReadonly<ActivityImportFoodUpload>},
        TContext
      > => {

      const mutationOptions = getCompanyReportsImportsUploadCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsImportsFoodCreate = (
    companyReportUuid: string,
    activityImportFood: NonReadonly<ActivityImportFood>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ActivityImportBase>(
      {url: `/company-reports/${companyReportUuid}/imports/food`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: activityImportFood
    },
      options);
    }
  


export const getCompanyReportsImportsFoodCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsImportsFoodCreate>>, TError,{companyReportUuid: string;data: NonReadonly<ActivityImportFood>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsImportsFoodCreate>>, TError,{companyReportUuid: string;data: NonReadonly<ActivityImportFood>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsImportsFoodCreate>>, {companyReportUuid: string;data: NonReadonly<ActivityImportFood>}> = (props) => {
          const {companyReportUuid,data} = props ?? {};

          return  companyReportsImportsFoodCreate(companyReportUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsImportsFoodCreateMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsImportsFoodCreate>>>
    export type CompanyReportsImportsFoodCreateMutationBody = NonReadonly<ActivityImportFood>
    export type CompanyReportsImportsFoodCreateMutationError = unknown

    export const useCompanyReportsImportsFoodCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsImportsFoodCreate>>, TError,{companyReportUuid: string;data: NonReadonly<ActivityImportFood>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsImportsFoodCreate>>,
        TError,
        {companyReportUuid: string;data: NonReadonly<ActivityImportFood>},
        TContext
      > => {

      const mutationOptions = getCompanyReportsImportsFoodCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsImportsProcessFoodCreate = (
    companyReportUuid: string,
    processFoodRows: NonReadonly<ProcessFoodRows>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ActivityImportBase>(
      {url: `/company-reports/${companyReportUuid}/imports/process/food`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: processFoodRows
    },
      options);
    }
  


export const getCompanyReportsImportsProcessFoodCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsImportsProcessFoodCreate>>, TError,{companyReportUuid: string;data: NonReadonly<ProcessFoodRows>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsImportsProcessFoodCreate>>, TError,{companyReportUuid: string;data: NonReadonly<ProcessFoodRows>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsImportsProcessFoodCreate>>, {companyReportUuid: string;data: NonReadonly<ProcessFoodRows>}> = (props) => {
          const {companyReportUuid,data} = props ?? {};

          return  companyReportsImportsProcessFoodCreate(companyReportUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsImportsProcessFoodCreateMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsImportsProcessFoodCreate>>>
    export type CompanyReportsImportsProcessFoodCreateMutationBody = NonReadonly<ProcessFoodRows>
    export type CompanyReportsImportsProcessFoodCreateMutationError = unknown

    export const useCompanyReportsImportsProcessFoodCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsImportsProcessFoodCreate>>, TError,{companyReportUuid: string;data: NonReadonly<ProcessFoodRows>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsImportsProcessFoodCreate>>,
        TError,
        {companyReportUuid: string;data: NonReadonly<ProcessFoodRows>},
        TContext
      > => {

      const mutationOptions = getCompanyReportsImportsProcessFoodCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsPublishCreate = (
    companyReportUuid: string,
    companyReportCategoryStatus: NonReadonly<CompanyReportCategoryStatus[]>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<void>(
      {url: `/company-reports/${companyReportUuid}/publish`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: companyReportCategoryStatus
    },
      options);
    }
  


export const getCompanyReportsPublishCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsPublishCreate>>, TError,{companyReportUuid: string;data: NonReadonly<CompanyReportCategoryStatus[]>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsPublishCreate>>, TError,{companyReportUuid: string;data: NonReadonly<CompanyReportCategoryStatus[]>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsPublishCreate>>, {companyReportUuid: string;data: NonReadonly<CompanyReportCategoryStatus[]>}> = (props) => {
          const {companyReportUuid,data} = props ?? {};

          return  companyReportsPublishCreate(companyReportUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsPublishCreateMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsPublishCreate>>>
    export type CompanyReportsPublishCreateMutationBody = NonReadonly<CompanyReportCategoryStatus[]>
    export type CompanyReportsPublishCreateMutationError = unknown

    export const useCompanyReportsPublishCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsPublishCreate>>, TError,{companyReportUuid: string;data: NonReadonly<CompanyReportCategoryStatus[]>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsPublishCreate>>,
        TError,
        {companyReportUuid: string;data: NonReadonly<CompanyReportCategoryStatus[]>},
        TContext
      > => {

      const mutationOptions = getCompanyReportsPublishCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Retrieve the latest company report result and construct it manually.
 */
export const companyReportsResultsRetrieve = (
    companyReportUuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<CompanyReportResult>(
      {url: `/company-reports/${companyReportUuid}/results`, method: 'GET', signal
    },
      options);
    }
  

export const getCompanyReportsResultsRetrieveQueryKey = (companyReportUuid: string,) => {
    return [`/company-reports/${companyReportUuid}/results`] as const;
    }

    
export const getCompanyReportsResultsRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsResultsRetrieve>>, TError = unknown>(companyReportUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsResultsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsResultsRetrieveQueryKey(companyReportUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsResultsRetrieve>>> = ({ signal }) => companyReportsResultsRetrieve(companyReportUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsResultsRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsResultsRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsResultsRetrieve>>>
export type CompanyReportsResultsRetrieveInfiniteQueryError = unknown

export const useCompanyReportsResultsRetrieveInfinite = <TData = Awaited<ReturnType<typeof companyReportsResultsRetrieve>>, TError = unknown>(
 companyReportUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsResultsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsResultsRetrieveInfiniteQueryOptions(companyReportUuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsResultsRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsResultsRetrieve>>, TError = unknown>(companyReportUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsResultsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsResultsRetrieveQueryKey(companyReportUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsResultsRetrieve>>> = ({ signal }) => companyReportsResultsRetrieve(companyReportUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsResultsRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsResultsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsResultsRetrieve>>>
export type CompanyReportsResultsRetrieveQueryError = unknown

export const useCompanyReportsResultsRetrieve = <TData = Awaited<ReturnType<typeof companyReportsResultsRetrieve>>, TError = unknown>(
 companyReportUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsResultsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsResultsRetrieveQueryOptions(companyReportUuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsResultsDownloadRetrieve = (
    companyReportUuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<CompanyReportResult>(
      {url: `/company-reports/${companyReportUuid}/results/download`, method: 'GET', signal
    },
      options);
    }
  

export const getCompanyReportsResultsDownloadRetrieveQueryKey = (companyReportUuid: string,) => {
    return [`/company-reports/${companyReportUuid}/results/download`] as const;
    }

    
export const getCompanyReportsResultsDownloadRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsResultsDownloadRetrieve>>, TError = unknown>(companyReportUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsResultsDownloadRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsResultsDownloadRetrieveQueryKey(companyReportUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsResultsDownloadRetrieve>>> = ({ signal }) => companyReportsResultsDownloadRetrieve(companyReportUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsResultsDownloadRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsResultsDownloadRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsResultsDownloadRetrieve>>>
export type CompanyReportsResultsDownloadRetrieveInfiniteQueryError = unknown

export const useCompanyReportsResultsDownloadRetrieveInfinite = <TData = Awaited<ReturnType<typeof companyReportsResultsDownloadRetrieve>>, TError = unknown>(
 companyReportUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsResultsDownloadRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsResultsDownloadRetrieveInfiniteQueryOptions(companyReportUuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsResultsDownloadRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsResultsDownloadRetrieve>>, TError = unknown>(companyReportUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsResultsDownloadRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsResultsDownloadRetrieveQueryKey(companyReportUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsResultsDownloadRetrieve>>> = ({ signal }) => companyReportsResultsDownloadRetrieve(companyReportUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsResultsDownloadRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsResultsDownloadRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsResultsDownloadRetrieve>>>
export type CompanyReportsResultsDownloadRetrieveQueryError = unknown

export const useCompanyReportsResultsDownloadRetrieve = <TData = Awaited<ReturnType<typeof companyReportsResultsDownloadRetrieve>>, TError = unknown>(
 companyReportUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsResultsDownloadRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsResultsDownloadRetrieveQueryOptions(companyReportUuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsTimePeriodsList = (
    companyReportUuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<TimePeriod[]>(
      {url: `/company-reports/${companyReportUuid}/time-periods`, method: 'GET', signal
    },
      options);
    }
  

export const getCompanyReportsTimePeriodsListQueryKey = (companyReportUuid: string,) => {
    return [`/company-reports/${companyReportUuid}/time-periods`] as const;
    }

    
export const getCompanyReportsTimePeriodsListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsTimePeriodsList>>, TError = unknown>(companyReportUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsTimePeriodsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsTimePeriodsListQueryKey(companyReportUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsTimePeriodsList>>> = ({ signal }) => companyReportsTimePeriodsList(companyReportUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsTimePeriodsList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsTimePeriodsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsTimePeriodsList>>>
export type CompanyReportsTimePeriodsListInfiniteQueryError = unknown

export const useCompanyReportsTimePeriodsListInfinite = <TData = Awaited<ReturnType<typeof companyReportsTimePeriodsList>>, TError = unknown>(
 companyReportUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsTimePeriodsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsTimePeriodsListInfiniteQueryOptions(companyReportUuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsTimePeriodsListQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsTimePeriodsList>>, TError = unknown>(companyReportUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsTimePeriodsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsTimePeriodsListQueryKey(companyReportUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsTimePeriodsList>>> = ({ signal }) => companyReportsTimePeriodsList(companyReportUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsTimePeriodsList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsTimePeriodsListQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsTimePeriodsList>>>
export type CompanyReportsTimePeriodsListQueryError = unknown

export const useCompanyReportsTimePeriodsList = <TData = Awaited<ReturnType<typeof companyReportsTimePeriodsList>>, TError = unknown>(
 companyReportUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsTimePeriodsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsTimePeriodsListQueryOptions(companyReportUuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsActivityTypesUnitsList = (
    activityTypeSlug: string,
    params?: CompanyReportsActivityTypesUnitsListParams,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<ActivityUnit[]>(
      {url: `/company-reports/activity-types/${activityTypeSlug}/units`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getCompanyReportsActivityTypesUnitsListQueryKey = (activityTypeSlug: string,
    params?: CompanyReportsActivityTypesUnitsListParams,) => {
    return [`/company-reports/activity-types/${activityTypeSlug}/units`, ...(params ? [params]: [])] as const;
    }

    
export const getCompanyReportsActivityTypesUnitsListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsActivityTypesUnitsList>>, TError = unknown>(activityTypeSlug: string,
    params?: CompanyReportsActivityTypesUnitsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivityTypesUnitsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsActivityTypesUnitsListQueryKey(activityTypeSlug,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsActivityTypesUnitsList>>> = ({ signal, pageParam }) => companyReportsActivityTypesUnitsList(activityTypeSlug,{...params, page: pageParam || params?.['page']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(activityTypeSlug),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivityTypesUnitsList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsActivityTypesUnitsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivityTypesUnitsList>>>
export type CompanyReportsActivityTypesUnitsListInfiniteQueryError = unknown

export const useCompanyReportsActivityTypesUnitsListInfinite = <TData = Awaited<ReturnType<typeof companyReportsActivityTypesUnitsList>>, TError = unknown>(
 activityTypeSlug: string,
    params?: CompanyReportsActivityTypesUnitsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivityTypesUnitsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsActivityTypesUnitsListInfiniteQueryOptions(activityTypeSlug,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsActivityTypesUnitsListQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsActivityTypesUnitsList>>, TError = unknown>(activityTypeSlug: string,
    params?: CompanyReportsActivityTypesUnitsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivityTypesUnitsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsActivityTypesUnitsListQueryKey(activityTypeSlug,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsActivityTypesUnitsList>>> = ({ signal }) => companyReportsActivityTypesUnitsList(activityTypeSlug,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(activityTypeSlug),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivityTypesUnitsList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsActivityTypesUnitsListQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivityTypesUnitsList>>>
export type CompanyReportsActivityTypesUnitsListQueryError = unknown

export const useCompanyReportsActivityTypesUnitsList = <TData = Awaited<ReturnType<typeof companyReportsActivityTypesUnitsList>>, TError = unknown>(
 activityTypeSlug: string,
    params?: CompanyReportsActivityTypesUnitsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivityTypesUnitsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsActivityTypesUnitsListQueryOptions(activityTypeSlug,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsFuelsList = (
    params?: CompanyReportsFuelsListParams,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<ActivityFuel[]>(
      {url: `/company-reports/fuels`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getCompanyReportsFuelsListQueryKey = (params?: CompanyReportsFuelsListParams,) => {
    return [`/company-reports/fuels`, ...(params ? [params]: [])] as const;
    }

    
export const getCompanyReportsFuelsListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsFuelsList>>, TError = unknown>(params?: CompanyReportsFuelsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsFuelsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsFuelsListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsFuelsList>>> = ({ signal, pageParam }) => companyReportsFuelsList({...params, page: pageParam || params?.['page']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsFuelsList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsFuelsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsFuelsList>>>
export type CompanyReportsFuelsListInfiniteQueryError = unknown

export const useCompanyReportsFuelsListInfinite = <TData = Awaited<ReturnType<typeof companyReportsFuelsList>>, TError = unknown>(
 params?: CompanyReportsFuelsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsFuelsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsFuelsListInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsFuelsListQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsFuelsList>>, TError = unknown>(params?: CompanyReportsFuelsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsFuelsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsFuelsListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsFuelsList>>> = ({ signal }) => companyReportsFuelsList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsFuelsList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsFuelsListQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsFuelsList>>>
export type CompanyReportsFuelsListQueryError = unknown

export const useCompanyReportsFuelsList = <TData = Awaited<ReturnType<typeof companyReportsFuelsList>>, TError = unknown>(
 params?: CompanyReportsFuelsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsFuelsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsFuelsListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsGasesList = (
    params?: CompanyReportsGasesListParams,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<ActivityGas[]>(
      {url: `/company-reports/gases`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getCompanyReportsGasesListQueryKey = (params?: CompanyReportsGasesListParams,) => {
    return [`/company-reports/gases`, ...(params ? [params]: [])] as const;
    }

    
export const getCompanyReportsGasesListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsGasesList>>, TError = unknown>(params?: CompanyReportsGasesListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsGasesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsGasesListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsGasesList>>> = ({ signal, pageParam }) => companyReportsGasesList({...params, page: pageParam || params?.['page']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsGasesList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsGasesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsGasesList>>>
export type CompanyReportsGasesListInfiniteQueryError = unknown

export const useCompanyReportsGasesListInfinite = <TData = Awaited<ReturnType<typeof companyReportsGasesList>>, TError = unknown>(
 params?: CompanyReportsGasesListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsGasesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsGasesListInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsGasesListQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsGasesList>>, TError = unknown>(params?: CompanyReportsGasesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsGasesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsGasesListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsGasesList>>> = ({ signal }) => companyReportsGasesList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsGasesList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsGasesListQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsGasesList>>>
export type CompanyReportsGasesListQueryError = unknown

export const useCompanyReportsGasesList = <TData = Awaited<ReturnType<typeof companyReportsGasesList>>, TError = unknown>(
 params?: CompanyReportsGasesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsGasesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsGasesListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsSitesList = (
    params?: CompanyReportsSitesListParams,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<PaginatedSiteList>(
      {url: `/company-reports/sites`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getCompanyReportsSitesListQueryKey = (params?: CompanyReportsSitesListParams,) => {
    return [`/company-reports/sites`, ...(params ? [params]: [])] as const;
    }

    
export const getCompanyReportsSitesListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsSitesList>>, TError = unknown>(params?: CompanyReportsSitesListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsSitesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsSitesListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsSitesList>>> = ({ signal, pageParam }) => companyReportsSitesList({...params, page: pageParam || params?.['page']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsSitesList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsSitesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsSitesList>>>
export type CompanyReportsSitesListInfiniteQueryError = unknown

export const useCompanyReportsSitesListInfinite = <TData = Awaited<ReturnType<typeof companyReportsSitesList>>, TError = unknown>(
 params?: CompanyReportsSitesListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsSitesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsSitesListInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsSitesListQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsSitesList>>, TError = unknown>(params?: CompanyReportsSitesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsSitesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsSitesListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsSitesList>>> = ({ signal }) => companyReportsSitesList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsSitesList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsSitesListQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsSitesList>>>
export type CompanyReportsSitesListQueryError = unknown

export const useCompanyReportsSitesList = <TData = Awaited<ReturnType<typeof companyReportsSitesList>>, TError = unknown>(
 params?: CompanyReportsSitesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsSitesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsSitesListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsSitesCreate = (
    site: NonReadonly<Site>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<Site>(
      {url: `/company-reports/sites`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: site
    },
      options);
    }
  


export const getCompanyReportsSitesCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesCreate>>, TError,{data: NonReadonly<Site>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesCreate>>, TError,{data: NonReadonly<Site>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsSitesCreate>>, {data: NonReadonly<Site>}> = (props) => {
          const {data} = props ?? {};

          return  companyReportsSitesCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsSitesCreateMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsSitesCreate>>>
    export type CompanyReportsSitesCreateMutationBody = NonReadonly<Site>
    export type CompanyReportsSitesCreateMutationError = unknown

    export const useCompanyReportsSitesCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesCreate>>, TError,{data: NonReadonly<Site>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsSitesCreate>>,
        TError,
        {data: NonReadonly<Site>},
        TContext
      > => {

      const mutationOptions = getCompanyReportsSitesCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsSitesRetrieve = (
    uuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<Site>(
      {url: `/company-reports/sites/${uuid}`, method: 'GET', signal
    },
      options);
    }
  

export const getCompanyReportsSitesRetrieveQueryKey = (uuid: string,) => {
    return [`/company-reports/sites/${uuid}`] as const;
    }

    
export const getCompanyReportsSitesRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsSitesRetrieve>>, TError = unknown>(uuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsSitesRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsSitesRetrieveQueryKey(uuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsSitesRetrieve>>> = ({ signal }) => companyReportsSitesRetrieve(uuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(uuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsSitesRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsSitesRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsSitesRetrieve>>>
export type CompanyReportsSitesRetrieveInfiniteQueryError = unknown

export const useCompanyReportsSitesRetrieveInfinite = <TData = Awaited<ReturnType<typeof companyReportsSitesRetrieve>>, TError = unknown>(
 uuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsSitesRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsSitesRetrieveInfiniteQueryOptions(uuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsSitesRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsSitesRetrieve>>, TError = unknown>(uuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsSitesRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsSitesRetrieveQueryKey(uuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsSitesRetrieve>>> = ({ signal }) => companyReportsSitesRetrieve(uuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(uuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsSitesRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsSitesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsSitesRetrieve>>>
export type CompanyReportsSitesRetrieveQueryError = unknown

export const useCompanyReportsSitesRetrieve = <TData = Awaited<ReturnType<typeof companyReportsSitesRetrieve>>, TError = unknown>(
 uuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsSitesRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsSitesRetrieveQueryOptions(uuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsSitesUpdate = (
    uuid: string,
    site: NonReadonly<Site>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<Site>(
      {url: `/company-reports/sites/${uuid}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: site
    },
      options);
    }
  


export const getCompanyReportsSitesUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesUpdate>>, TError,{uuid: string;data: NonReadonly<Site>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesUpdate>>, TError,{uuid: string;data: NonReadonly<Site>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsSitesUpdate>>, {uuid: string;data: NonReadonly<Site>}> = (props) => {
          const {uuid,data} = props ?? {};

          return  companyReportsSitesUpdate(uuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsSitesUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsSitesUpdate>>>
    export type CompanyReportsSitesUpdateMutationBody = NonReadonly<Site>
    export type CompanyReportsSitesUpdateMutationError = unknown

    export const useCompanyReportsSitesUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesUpdate>>, TError,{uuid: string;data: NonReadonly<Site>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsSitesUpdate>>,
        TError,
        {uuid: string;data: NonReadonly<Site>},
        TContext
      > => {

      const mutationOptions = getCompanyReportsSitesUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsSitesPartialUpdate = (
    uuid: string,
    patchedSite: NonReadonly<PatchedSite>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<Site>(
      {url: `/company-reports/sites/${uuid}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedSite
    },
      options);
    }
  


export const getCompanyReportsSitesPartialUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesPartialUpdate>>, TError,{uuid: string;data: NonReadonly<PatchedSite>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesPartialUpdate>>, TError,{uuid: string;data: NonReadonly<PatchedSite>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsSitesPartialUpdate>>, {uuid: string;data: NonReadonly<PatchedSite>}> = (props) => {
          const {uuid,data} = props ?? {};

          return  companyReportsSitesPartialUpdate(uuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsSitesPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsSitesPartialUpdate>>>
    export type CompanyReportsSitesPartialUpdateMutationBody = NonReadonly<PatchedSite>
    export type CompanyReportsSitesPartialUpdateMutationError = unknown

    export const useCompanyReportsSitesPartialUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesPartialUpdate>>, TError,{uuid: string;data: NonReadonly<PatchedSite>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsSitesPartialUpdate>>,
        TError,
        {uuid: string;data: NonReadonly<PatchedSite>},
        TContext
      > => {

      const mutationOptions = getCompanyReportsSitesPartialUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsSitesDestroy = (
    uuid: string,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<void>(
      {url: `/company-reports/sites/${uuid}`, method: 'DELETE'
    },
      options);
    }
  


export const getCompanyReportsSitesDestroyMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesDestroy>>, TError,{uuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesDestroy>>, TError,{uuid: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsSitesDestroy>>, {uuid: string}> = (props) => {
          const {uuid} = props ?? {};

          return  companyReportsSitesDestroy(uuid,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsSitesDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsSitesDestroy>>>
    
    export type CompanyReportsSitesDestroyMutationError = unknown

    export const useCompanyReportsSitesDestroy = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesDestroy>>, TError,{uuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsSitesDestroy>>,
        TError,
        {uuid: string},
        TContext
      > => {

      const mutationOptions = getCompanyReportsSitesDestroyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsSitesBulkCreate = (
    site: NonReadonly<Site[]>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<Site[]>(
      {url: `/company-reports/sites/bulk`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: site
    },
      options);
    }
  


export const getCompanyReportsSitesBulkCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesBulkCreate>>, TError,{data: NonReadonly<Site[]>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesBulkCreate>>, TError,{data: NonReadonly<Site[]>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsSitesBulkCreate>>, {data: NonReadonly<Site[]>}> = (props) => {
          const {data} = props ?? {};

          return  companyReportsSitesBulkCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsSitesBulkCreateMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsSitesBulkCreate>>>
    export type CompanyReportsSitesBulkCreateMutationBody = NonReadonly<Site[]>
    export type CompanyReportsSitesBulkCreateMutationError = unknown

    export const useCompanyReportsSitesBulkCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesBulkCreate>>, TError,{data: NonReadonly<Site[]>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsSitesBulkCreate>>,
        TError,
        {data: NonReadonly<Site[]>},
        TContext
      > => {

      const mutationOptions = getCompanyReportsSitesBulkCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsSitesElectricitySuppliesList = (
    params?: CompanyReportsSitesElectricitySuppliesListParams,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<PaginatedElectricitySupplyList>(
      {url: `/company-reports/sites/electricity-supplies`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getCompanyReportsSitesElectricitySuppliesListQueryKey = (params?: CompanyReportsSitesElectricitySuppliesListParams,) => {
    return [`/company-reports/sites/electricity-supplies`, ...(params ? [params]: [])] as const;
    }

    
export const getCompanyReportsSitesElectricitySuppliesListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesList>>, TError = unknown>(params?: CompanyReportsSitesElectricitySuppliesListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsSitesElectricitySuppliesListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesList>>> = ({ signal, pageParam }) => companyReportsSitesElectricitySuppliesList({...params, page: pageParam || params?.['page']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsSitesElectricitySuppliesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesList>>>
export type CompanyReportsSitesElectricitySuppliesListInfiniteQueryError = unknown

export const useCompanyReportsSitesElectricitySuppliesListInfinite = <TData = Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesList>>, TError = unknown>(
 params?: CompanyReportsSitesElectricitySuppliesListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsSitesElectricitySuppliesListInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsSitesElectricitySuppliesListQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesList>>, TError = unknown>(params?: CompanyReportsSitesElectricitySuppliesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsSitesElectricitySuppliesListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesList>>> = ({ signal }) => companyReportsSitesElectricitySuppliesList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsSitesElectricitySuppliesListQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesList>>>
export type CompanyReportsSitesElectricitySuppliesListQueryError = unknown

export const useCompanyReportsSitesElectricitySuppliesList = <TData = Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesList>>, TError = unknown>(
 params?: CompanyReportsSitesElectricitySuppliesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsSitesElectricitySuppliesListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsSitesElectricitySuppliesCreate = (
    electricitySupply: NonReadonly<ElectricitySupply>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ElectricitySupply>(
      {url: `/company-reports/sites/electricity-supplies`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: electricitySupply
    },
      options);
    }
  


export const getCompanyReportsSitesElectricitySuppliesCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesCreate>>, TError,{data: NonReadonly<ElectricitySupply>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesCreate>>, TError,{data: NonReadonly<ElectricitySupply>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesCreate>>, {data: NonReadonly<ElectricitySupply>}> = (props) => {
          const {data} = props ?? {};

          return  companyReportsSitesElectricitySuppliesCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsSitesElectricitySuppliesCreateMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesCreate>>>
    export type CompanyReportsSitesElectricitySuppliesCreateMutationBody = NonReadonly<ElectricitySupply>
    export type CompanyReportsSitesElectricitySuppliesCreateMutationError = unknown

    export const useCompanyReportsSitesElectricitySuppliesCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesCreate>>, TError,{data: NonReadonly<ElectricitySupply>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesCreate>>,
        TError,
        {data: NonReadonly<ElectricitySupply>},
        TContext
      > => {

      const mutationOptions = getCompanyReportsSitesElectricitySuppliesCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsSitesElectricitySuppliesRetrieve = (
    uuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<ElectricitySupply>(
      {url: `/company-reports/sites/electricity-supplies/${uuid}`, method: 'GET', signal
    },
      options);
    }
  

export const getCompanyReportsSitesElectricitySuppliesRetrieveQueryKey = (uuid: string,) => {
    return [`/company-reports/sites/electricity-supplies/${uuid}`] as const;
    }

    
export const getCompanyReportsSitesElectricitySuppliesRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesRetrieve>>, TError = unknown>(uuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsSitesElectricitySuppliesRetrieveQueryKey(uuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesRetrieve>>> = ({ signal }) => companyReportsSitesElectricitySuppliesRetrieve(uuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(uuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsSitesElectricitySuppliesRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesRetrieve>>>
export type CompanyReportsSitesElectricitySuppliesRetrieveInfiniteQueryError = unknown

export const useCompanyReportsSitesElectricitySuppliesRetrieveInfinite = <TData = Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesRetrieve>>, TError = unknown>(
 uuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsSitesElectricitySuppliesRetrieveInfiniteQueryOptions(uuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsSitesElectricitySuppliesRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesRetrieve>>, TError = unknown>(uuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsSitesElectricitySuppliesRetrieveQueryKey(uuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesRetrieve>>> = ({ signal }) => companyReportsSitesElectricitySuppliesRetrieve(uuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(uuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsSitesElectricitySuppliesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesRetrieve>>>
export type CompanyReportsSitesElectricitySuppliesRetrieveQueryError = unknown

export const useCompanyReportsSitesElectricitySuppliesRetrieve = <TData = Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesRetrieve>>, TError = unknown>(
 uuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsSitesElectricitySuppliesRetrieveQueryOptions(uuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsSitesElectricitySuppliesUpdate = (
    uuid: string,
    electricitySupply: NonReadonly<ElectricitySupply>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ElectricitySupply>(
      {url: `/company-reports/sites/electricity-supplies/${uuid}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: electricitySupply
    },
      options);
    }
  


export const getCompanyReportsSitesElectricitySuppliesUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesUpdate>>, TError,{uuid: string;data: NonReadonly<ElectricitySupply>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesUpdate>>, TError,{uuid: string;data: NonReadonly<ElectricitySupply>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesUpdate>>, {uuid: string;data: NonReadonly<ElectricitySupply>}> = (props) => {
          const {uuid,data} = props ?? {};

          return  companyReportsSitesElectricitySuppliesUpdate(uuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsSitesElectricitySuppliesUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesUpdate>>>
    export type CompanyReportsSitesElectricitySuppliesUpdateMutationBody = NonReadonly<ElectricitySupply>
    export type CompanyReportsSitesElectricitySuppliesUpdateMutationError = unknown

    export const useCompanyReportsSitesElectricitySuppliesUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesUpdate>>, TError,{uuid: string;data: NonReadonly<ElectricitySupply>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesUpdate>>,
        TError,
        {uuid: string;data: NonReadonly<ElectricitySupply>},
        TContext
      > => {

      const mutationOptions = getCompanyReportsSitesElectricitySuppliesUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsSitesElectricitySuppliesPartialUpdate = (
    uuid: string,
    patchedElectricitySupply: NonReadonly<PatchedElectricitySupply>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ElectricitySupply>(
      {url: `/company-reports/sites/electricity-supplies/${uuid}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedElectricitySupply
    },
      options);
    }
  


export const getCompanyReportsSitesElectricitySuppliesPartialUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesPartialUpdate>>, TError,{uuid: string;data: NonReadonly<PatchedElectricitySupply>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesPartialUpdate>>, TError,{uuid: string;data: NonReadonly<PatchedElectricitySupply>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesPartialUpdate>>, {uuid: string;data: NonReadonly<PatchedElectricitySupply>}> = (props) => {
          const {uuid,data} = props ?? {};

          return  companyReportsSitesElectricitySuppliesPartialUpdate(uuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsSitesElectricitySuppliesPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesPartialUpdate>>>
    export type CompanyReportsSitesElectricitySuppliesPartialUpdateMutationBody = NonReadonly<PatchedElectricitySupply>
    export type CompanyReportsSitesElectricitySuppliesPartialUpdateMutationError = unknown

    export const useCompanyReportsSitesElectricitySuppliesPartialUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesPartialUpdate>>, TError,{uuid: string;data: NonReadonly<PatchedElectricitySupply>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesPartialUpdate>>,
        TError,
        {uuid: string;data: NonReadonly<PatchedElectricitySupply>},
        TContext
      > => {

      const mutationOptions = getCompanyReportsSitesElectricitySuppliesPartialUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsSitesElectricitySuppliesDestroy = (
    uuid: string,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<void>(
      {url: `/company-reports/sites/electricity-supplies/${uuid}`, method: 'DELETE'
    },
      options);
    }
  


export const getCompanyReportsSitesElectricitySuppliesDestroyMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesDestroy>>, TError,{uuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesDestroy>>, TError,{uuid: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesDestroy>>, {uuid: string}> = (props) => {
          const {uuid} = props ?? {};

          return  companyReportsSitesElectricitySuppliesDestroy(uuid,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsSitesElectricitySuppliesDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesDestroy>>>
    
    export type CompanyReportsSitesElectricitySuppliesDestroyMutationError = unknown

    export const useCompanyReportsSitesElectricitySuppliesDestroy = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesDestroy>>, TError,{uuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesDestroy>>,
        TError,
        {uuid: string},
        TContext
      > => {

      const mutationOptions = getCompanyReportsSitesElectricitySuppliesDestroyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    