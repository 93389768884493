/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * My Emissions API
 * We calculate food carbon footprints
 * OpenAPI spec version: 1.0.0 (v1)
 */

/**
 * * `packs` - Packs
* `total-weight` - Total Weight
 */
export type InputModeEnum = typeof InputModeEnum[keyof typeof InputModeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InputModeEnum = {
  packs: 'packs',
  'total-weight': 'total-weight',
} as const;
