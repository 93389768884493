import {
  Row,
  RowData,
  flexRender,
  type Table as TanstackTable,
} from '@tanstack/react-table';
import * as React from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@shared/components/ui/table';

import { DataTablePagination } from '@shared/components/data-table/data-table-pagination';
import { cn } from '@shared/lib/utils';
import { ClassValue } from 'clsx';
import Loader from '../loader';
import { ColumnClassName, DataTableHeaderProps } from './types';

declare module '@tanstack/react-table' {
  interface ColumnMeta<TData extends RowData, TValue> {
    label: string;
    sorting: {
      ascLabel: string;
      descLabel: string;
    };
  }
}

export interface DataTableProps<TData>
  extends React.HTMLAttributes<HTMLDivElement> {
  table: TanstackTable<TData>;
  enablePagination?: boolean;
  onRowClick?: (row?: Row<TData>) => void;
  customRowItem?: (row: Row<TData>) => React.ReactNode;
  showHeader?: boolean;
  rowClassName?: (row: Row<TData>) => ClassValue;
  headerConfig?: DataTableHeaderProps;
  isLoading?: boolean;
  cellClassName?: ClassValue;
  columnClassName?: ColumnClassName;
}
export function DataTable<TData>({
  table,
  children,
  enablePagination = false,
  rowClassName,
  onRowClick,
  showHeader = true,
  headerConfig,
  className,
  customRowItem,
  isLoading,
  cellClassName,
  columnClassName = {},
  ...props
}: DataTableProps<TData>) {
  const hasRows = table.getRowModel().rows?.length > 0;

  return (
    <div className={cn('w-full space-y-2.5 overflow-auto ')} {...props}>
      {children}
      <div>
        <Table className={cn('relative overflow-hidden rounded-lg', className)}>
          {showHeader && (
            <TableHeader className={cn(headerConfig?.className)}>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id} className="">
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead
                        colSpan={header.colSpan}
                        key={header.id}
                        className={cn(headerConfig?.cell, 'align-top', {
                          [columnClassName[header.column.id] as string]:
                            columnClassName[header.column.id] || '',
                        })}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
          )}
          <TableBody className="relative">
            {isLoading && (
              <TableRow>
                <TableCell
                  colSpan={table.getAllColumns().length}
                  className="h-24 text-center"
                >
                  <Loader />
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              hasRows &&
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  className={cn(
                    '',
                    onRowClick && 'cursor-pointer',
                    rowClassName && rowClassName(row)
                  )}
                >
                  {customRowItem ? (
                    <span key={row.id}>{customRowItem(row)}</span>
                  ) : (
                    <>
                      {row.getVisibleCells().map((cell) => (
                        <TableCell
                          key={cell.id}
                          className={cn(
                            '',
                            {
                              [columnClassName[cell.column.id] as string]:
                                columnClassName[cell.column.id] || '',
                            },
                            cellClassName
                          )}
                          onClick={() => {
                            cell.column.id !== 'actions' &&
                              onRowClick &&
                              onRowClick(row);
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      ))}
                    </>
                  )}
                </TableRow>
              ))}
            {!isLoading && !hasRows && (
              <TableRow>
                <TableCell
                  colSpan={table.getAllColumns().length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {enablePagination && (
          <div className="mr-4 flex items-center justify-end space-x-2 py-4">
            <DataTablePagination table={table} />
          </div>
        )}
      </div>
    </div>
  );
}
